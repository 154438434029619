import {useNavigate} from "react-router-dom";
import Header from "../components/Authentification/header";
import LoginForm from "../components/Authentification/loginForm";

export default function Authentification() {

    const navigate = useNavigate();


    return (
        <>
            <Header navigate={navigate}/>
            <div className="loginFormPosition">
                <LoginForm navigate={navigate}/>
            </div>
            <p className='copyright'>2024 - Capbox • Tous droits réservés</p>
        </>
    );
}
