import React, { useContext } from "react";

import ContactInfosPanel from "../editingPages/informations/ContactInfosPanel";
import { ContactPopContext } from "../../../context/ContactPopContext";
import { GetApiContext } from "../../../context/apiCalls/getApiCalls";
function MarginInfos({
  invoiceRef,
  interlocutors,
  contact,
  setContact,
  contacts,
  setContacts,
  handleInterChange,
  interlocutorSelected,
}) {
  const getApiContext = useContext(GetApiContext);
  const contactPopContext = useContext(ContactPopContext);
  const handleClickModal = (e) => {
    e.preventDefault();
    console.log(contactPopContext.showContactPopup);

    if (contactPopContext.showContactPopup === false) {
      getApiContext.getContactsList().then((response) => {
        setContacts(response.listContacts);
        contactPopContext.setShowContactPopup(true);
      });
    }
  };

  function handleSelectedContact(index) {
    console.log(index);
    contacts.forEach((element) => {
      if (element.id === index) {
        setContact(element);
      }
    });
    contactPopContext.setShowContactPopup(false);
  }
  return (
    <>
      <div className="editingInfos">
        <div className="editingInfos__titleContainer">
          <h2 className="editingInfos__titleContainer_title">INFORMATIONS</h2>
          <hr className="editingInfos__titleContainer_line" />
        </div>
        <div className="editingInfos__firstSection">
          <div className="editingInfos__firstSection_nameContainer">
            <h3 className="editingInfos__firstSection_nameContainer_title">
              Référence facture :
            </h3>
            <div className="editingInfos__firstSection_nameContainer_inputContainer">
              <input
                className="editingInfos__firstSection_nameContainer_input"
                autoComplete="off"
                type="text"
                name="invoiceRef"
                disabled
                defaultValue={invoiceRef}
                placeholder="EXEMPLE DE FACTURE"
              />
            </div>
          </div>
          <div className="editingInfos__firstSection_interContainer">
            <h3 className="editingInfos__firstSection_interContainer_title">
              Affaire suivie par :
            </h3>
            <select
              value={interlocutorSelected}
              onChange={handleInterChange}
              className="editingInfos__firstSection_interContainer_select"
            >
              {interlocutors?.map((interlocutor, index) => (
                <option
                  value={interlocutor.id}
                  key={index}
                  className="editingInfos__firstSection_interContainer_option"
                >
                  {interlocutor.last_name} {interlocutor.first_name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <ContactInfosPanel
          contact={contact}
          handleClickModal={handleClickModal}
          setContacts={setContacts}
          onSelectContact={handleSelectedContact}
        />
      </div>
    </>
  );
}

export default MarginInfos;
