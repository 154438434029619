import MainButton from "../addOns/buttonComponent/MainButton";
import capboxLogo from "../../assets/images/CapBox.svg";
import capachatLogo from "../../assets/images/CapAchat1.png";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

export default function Footer({navigate}) {
    return (
        <div className="loginFooter">
            <div className="loginFooter__leftContainer">
            <img onClick={(e) => navigate('/')} className='loginFooter__leftContainer_logo' src={capboxLogo} alt=""/>
                <p className='loginFooter__rightContainer_txt'>Une solution poposé par</p>
                <img onClick={()=> window.open("https://www.cap-achat.com/")} className='loginFooter__leftContainer_logo' src={capachatLogo} alt=""/>
            </div>
            <div className='loginFooter__rightContainer'>
                <MainButton className='loginFooter__rightContainer_button' buttonIcon={faPlus} buttonText={"En savoir"}/>
            </div>
        </div>
    );
}