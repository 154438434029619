export default function DepositInputs({
  rateRef,
  totalTTCRef,
  rate,
  depositTotalTtc,
  handleCalc,
}) {
  return (
    <>
      <h2 className="depositInputsTitle">Informations de l'acompte</h2>
      <hr className="depositInputsTitle_line" />
      <div className="depositInputs">
        <div className="depositInputs__content">
          <div className="depositInputs__content_percent">
            <label htmlFor="deposit-percent">Pourcentage de l'acompte</label>
            <div className="depositInputs__content_percent_wrapper">
              <input
                ref={rateRef}
                id="rateInput"
                onChange={handleCalc}
                value={rate}
                min={0}
                step="0.01"
                max={100}
                type="number"
                placeholder="10"
                onWheel={(e) => e.target.blur()}
              />{" "}
              %
            </div>
          </div>
          <div className="depositInputs__content_amount">
            <label htmlFor="deposit-amount">Montant de l'acompte</label>
            <div className="depositInputs__content_amount_wrapper">
              <input
                id="totalDepositInput"
                ref={totalTTCRef}
                onChange={handleCalc}
                min={0}
                step={0.01}
                value={depositTotalTtc}
                type="number"
                placeholder="523,25"
                onWheel={(e) => e.target.blur()}
              />{" "}
              €
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
