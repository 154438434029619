import modifier_blanc from "../../assets/images/picto/modifier_blanc.svg";
import cross from "../../assets/images/picto/orange_cross.svg";
import envelope from "../../assets/images/picto/envelope.png";
import { useLocation, useNavigate } from "react-router-dom";
import NavButton from "./navButton";
import Logo from "../../assets/images/CapAchatLogo.svg";
import capBoxLogo from "../../assets/images/CapBox.svg";
import useAuth from "../../hooks/useAuth";
import { useAuthState } from "../../context/authContext";
import roleVerify from "../../utils/roleVerify";
import { useContext, useEffect } from "react";
import { ContactPopContext } from "../../context/ContactPopContext";
import { PopupContext } from "../../context/PopupContext";

export default function Nav({ setShowNav }) {
  const { logout } = useAuth();
  const { roleId } = useAuthState();
  const contactPopContext = useContext(ContactPopContext);
  const { setPopupVisibility } = useContext(PopupContext);

  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const handleDisconnect = () => {
    logout();
    navigate("/login");
  };

  useEffect(() => {
    //console.log("path", path, 'contactPopContext', contactPopContext);
    contactPopContext.setShowContactPopup(false);
    setPopupVisibility("showDepositPopup", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const navButtonList = [
    {
      path: path,
      name: "tableau de bord",
      link: "/",
      roleIdList: ["1", "2", "3", "4", "5", "6"],
    },
    {
      path: path,
      name: "contacts",
      link: "/contactsList",
      roleIdList: ["1", "2", "3"],
    },
    {
      path: path,
      name: "devis",
      link: "/quotesList",
      roleIdList: ["1", "2", "3", "4", "5", "6"],
    },
    {
      path: path,
      name: "factures",
      link: "/invoicesList",
      roleIdList: ["1", "2", "3", "4", "5", "6"],
    },
    {
      path: path,
      name: "acomptes",
      link: "/depositsList",
      roleIdList: ["1", "2", "3", "4", "5", "6"],
    },
    {
      path: path,
      name: "marges",
      link: "/marginsList",
      roleIdList: ["1", "2", "3"],
    },
    {
      path: path,
      name: "dépenses",
      link: "/ordersList",
      roleIdList: ["1", "2", "3"],
    },
    {
      path: path,
      name: "avoirs",
      link: "/creditList",
      roleIdList: ["1", "2", "3"],
    },
    {
      path: path,
      name: "catalogues",
      link: "/catalogList",
      roleIdList: ["1", "2", "3"],
    },
  ];

  return (
    <>
      <div className="navigation">
        <div className="navigation__header">
          <figure className="navigation__header_figure">
            <img
              className="navigation__header_figure_logo"
              src={capBoxLogo}
              alt="logo cap-box"
            />
          </figure>
        </div>
        <div className="navigation__container">
          <div className="navigation__container_links">
            <ul className="navigation__container_links_ul">
              {navButtonList.map((navButton, index) => {
                return (
                  roleVerify(navButton.roleIdList, roleId) && (
                    <NavButton
                      key={index}
                      path={navButton.path}
                      activePathName={navButton.link}
                      name={navButton.name}
                      link={navButton.link}
                    />
                  )
                );
              })}

              {
                // <>
                //     <li className="navigation__container_links_ul_li"><Link className={`navigation__container_links_ul_li_link ${path === '/' && 'activeNavButton'}`} to="/"><span className={`navigation__container_links_ul_li_link_span`}></span>tableau de bord</Link></li>
                //     <NavButton path={path} activePathName={"/contact"} name={"clients"} link={'/contactsList'} />
                //     <NavButton path={path} activePathName={"/quote"} name={"vos devis"} link={'/quotesList'} />
                //     <NavButton path={path} activePathName={"/invoice"} name={"vos factures"} link={'/invoicesList'} />
                //     <NavButton path={path} activePathName={"/margin"} name={"marges"} link={'/marginsList'} />
                //     <NavButton path={path} activePathName={"/order"} name={"dépenses"} link={'/ordersList'} />
                //     <NavButton path={path} activePathName={"/credit"} name={"avoirs"} link={'/creditList'} />
                //     <NavButton path={path} activePathName={"/deposit"} name={"acomptes"} link={'/depositsList'} />
                //     <NavButton path={path} activePathName={"/catalog"} name={"catalogues"} link={'/catalogList'} />
                // </>
              }
            </ul>
          </div>
          <div className="navigation__container_buttons">
            <p
              onClick={() => navigate("/settings")}
              className="navigation__container_buttons_link"
            >
              paramètres
              <img
                className="navigation__container_buttons_link_icon"
                src={modifier_blanc}
                alt="engreuner"
              />
            </p>
            <button
              onClick={handleDisconnect}
              className="navigation__container_buttons_button"
            >
              déconnexion
              <img
                className="navigation__containers_buttons_button_icon"
                src={cross}
                alt="croix"
              />
            </button>
          </div>

          <div className="navigation__container_footer">
            <p className="navigation__container_footer_p">
              Une solution proposé par
            </p>
            <img
              className="navigation__container_footer_logo"
              src={Logo}
              alt="logo cap-achat"
            />
            <div className="navigation__container_footer_buttons">
              <button className="navigation__container_footer_buttons_button">
                En savoir +
              </button>
              <button className="navigation__container_footer_buttons_button">
                <img
                  className="navigation__container_footer_buttons_button_icon"
                  src={envelope}
                  alt="enveloppe"
                />
                Contact
              </button>
            </div>
            <p className="navigation__container_footer_p">
              2024 - CapBox - Tous droits réservés
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
