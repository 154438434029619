import BarCharts from "./BarCharts";
import Pie from "./Pie";
import { format } from "date-fns";
import React, { useEffect, useState, useMemo, useContext } from "react";
import { GetApiContext } from "../../../context/apiCalls/getApiCalls";
import DashboardWelcomePop from "./DashboardWelcomePop";

function Dashboard() {
  // Variables
  const currentYear = new Date().getFullYear();

  const months = useMemo(
    () => [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre",
    ],
    []
  );

  // useState
  const getApiCall = useContext(GetApiContext);

  const [rate, setRate] = useState("HT");
  const [user, setUser] = useState({});
  const [society, setSociety] = useState({});

  const [generateYearOptions, setGenerateYearOptions] = useState([currentYear]);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const [generateMonthOptions, setGenerateMonthOptions] = useState(months);
  const [selectedMonth, setSelectedMonth] = useState("janvier");

  const [sumPerMonth, setSumPerMonth] = useState({});
  const [sumPerMonthPreviousY, setSumPerMonthPreviousY] = useState({});

  const [sumOrderPerMonth, setSumOrderPerMonth] = useState();

  const [nbValidAndInvalid, setNbValidAndInvalid] = useState({
    valid: 0,
    invalid: 0,
  });

  const [startPeriodYear, setStartPeriodYear] = useState();
  const [finishPeriodYear, setFinishPeriodYear] = useState();

  const selectedMonthNumber = months.indexOf(selectedMonth);
  const selectDate = new Date(selectedYear, selectedMonthNumber, 1);
  const selectedDate = format(selectDate, "yyyy-MM-dd");

  const [showWelcomePop, setShowWelcomePop] = useState(
    window.innerWidth > 1400
  );
  const [doubleBar, setDoubleBar] = useState(false);

  useEffect(() => {
    getApiCall
      .getDashboardData(selectedDate, rate, selectedMonthNumber)
      .then((response) => {
        // console.log(response);
        setUser(response.user);
        setSociety(response.society);
        setSumPerMonth(response.sumPerMonth);
        setSumPerMonthPreviousY(response.sumPerMonthPreviousY);
        setSumOrderPerMonth(response.sumOrderPerMonth);
        setNbValidAndInvalid(
          response.nbValidAndInvalid || { valid: 0, invalid: 0 }
        );
        setStartPeriodYear(response.startPeriodYear);
        setFinishPeriodYear(response.finishPeriodYear);
      })
      .catch((error) => {
        console.error("Erreur lors de la requête :", error);
      });
  }, [selectedDate, rate, selectedMonth, getApiCall, selectedMonthNumber]);

  // Années
  useEffect(() => {
    const yearOptions = [];
    for (let year = currentYear; year >= currentYear - 5; year--) {
      yearOptions.push(year);
    }
    setGenerateYearOptions(yearOptions);
  }, [currentYear]);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setSelectedMonth("janvier");
  };

  // Mois
  useEffect(() => {
    const monthOptions = months.map((monthName) => {
      return {
        name: monthName,
        value: new Date(
          selectedYear,
          months.indexOf(monthName),
          1
        ).toLocaleString("default", {
          month: "long",
        }),
      };
    });
    setGenerateMonthOptions(monthOptions);
  }, [months, selectedYear]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  // Type de montant
  const handleChange = () => {
    setRate(rate === "HT" ? "TTC" : "HT");
  };

  //Si la fnetre est inférieure à 1400 pixels, on ferme la welcome pop de façon dynamique
  useEffect(() => {
    if (showWelcomePop && window.innerWidth < 1400) {
      setShowWelcomePop(false);
    }
    // eslint-disable-next-line
  }, [window.innerWidth, showWelcomePop]);

  return (
    <>
      {/* <TabBar elem="dashboard" /> */}
      <div className="">
        <div className="dashboard-container">
          <section className="dashboard-container__header">
            <div className="dashboard-container__header_head">
              <h2> Bonjour {user?.first_name} 🙂 </h2>
              <p>
                Entreprise{" "}
                <span className="dashboard-container__header_head_society-name">
                  {" "}
                  {society?.societyName}{" "}
                </span>
              </p>
            </div>
            <div className="orangeLine"> </div>
            <div className="dashboard-container__header_head_content">
              <h1 className="dashboard-container__header_head_content_title">
                VOTRE <span className="titleSpan"> TABLEAU DE BORD </span>
              </h1>
            </div>
          </section>
          <div className={"dashboard-container__content"}>
            {showWelcomePop && (
              <DashboardWelcomePop setShowWelcomePop={setShowWelcomePop} />
            )}
            <BarCharts
              rate={rate}
              sumPerMonth={sumPerMonth}
              sumPerMonthPreviousY={sumPerMonthPreviousY}
              sumOrderPerMonth={sumOrderPerMonth}
              startPeriodYear={startPeriodYear}
              finishPeriodYear={finishPeriodYear}
              xAxisProps={{ tickFormatter: (value) => value, interval: 0 }}
              doubleBar={doubleBar}
              setDoubleBar={setDoubleBar}
              welcomePop={showWelcomePop}
              handleYearChange={handleYearChange}
              handleMonthChange={handleMonthChange}
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
              generateYearOptions={generateYearOptions}
              generateMonthOptions={generateMonthOptions}
              handleChange={handleChange}
            />
          </div>

          <Pie
            nbValidAndInvalid={nbValidAndInvalid}
            startPeriodYear={startPeriodYear}
            finishPeriodYear={finishPeriodYear}
          />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
