import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState, useContext, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import QuoteGroup from "../../components/customer/quotes/form/QuoteGroup";
import MailPop from "../../components/addOns/MailPop";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/appContext";
import { ContactPopContext } from "../../context/ContactPopContext";
import MarginCreator from "../../components/addOns/MarginCreator";
import LinkedDepositList from "../../components/customer/deposit/LinkedDepositList";
import EditingMainHeader from "../../components/addOns/headers/EditingMainHeader";
import QuoteInfos from "../../components/customer/quotes/form/QuoteInfos";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { GetApiContext } from "../../context/apiCalls/getApiCalls";
import { PostApiContext } from "../../context/apiCalls/postApiCalls";
import { DeleteApiContext } from "../../context/apiCalls/deleteApiCalls";
import { useAuthState } from "../../context/authContext";
import { getCurrentDate } from "../../utils/utils";
import { format } from "date-fns";
import CatalogPop from "../../components/addOns/popComponents/CatalogPop";
import PriceRecap from "../../components/customer/invoices/invoice/recap/priceReacp";
import Loader from "../../components/addOns/loader";

function Quote() {
  const { roleId } = useAuthState();

  let pathName = useLocation().pathname.split("/")[2];
  let { id } = useParams();
  let navigate = useNavigate();
  const appContext = useContext(AppContext);
  const contactPopContext = useContext(ContactPopContext);
  const getApiContext = useContext(GetApiContext);
  const postApiContext = useContext(PostApiContext);
  const deleteApiContext = useContext(DeleteApiContext);
  const [quote, setQuote] = useState({});
  const [margin, setMargin] = useState();
  const [listTva, setListTva] = useState([]);
  const [newListTva, setNewListTva] = useState([]);
  // const [allTva, setAllTva] = useState();
  const [listUnit, setListUnit] = useState([]);
  const [ref, setRef] = useState("");
  const refRef = useRef();
  const dateRef = useRef(getCurrentDate());
  const [subject, setSubject] = useState("");
  const [interlocutors, setInterlocutors] = useState([]);
  const [contact, setContact] = useState(null);
  const [subTotalHT, setSubTotalHT] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [totalHT, setTotalHT] = useState(0);
  const [totalTTC, setTotalTTC] = useState(0);
  const [depositPercent, setDepositPercent] = useState(0);
  const [depositPrice, setDepositPrice] = useState(0);
  const [linkDeposit, setLinkDeposit] = useState();
  const [comment, setComment] = useState();
  const [showCalendar, setShowCalendar] = useState(false);
  const [contacts, setContacts] = useState();
  const [showCatalogues, setShowCatalogues] = useState(false);
  const [lineSelected, setLineSelected] = useState();
  const [grpIndexSelected, setGrpIndexSelected] = useState();
  const [lineIndexSelected, setLineIndexSelected] = useState();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRefresh(false);
    if (id) {
      getApiContext
        ?.getQuote(id)
        .then((response) => {
          // console.log(response.quote);
          setQuote(response.quote);
          setMargin(response.margin);
          if (newListTva.length < 1) {
            setListTva(response.listTva);
          }
          setListUnit(response.listUnit);
          setRef(response.quote?.ref);
          refRef.current.value = response.quote?.ref;
          setLinkDeposit(response.linkDeposit);
          setDiscount(parseFloat(response.quote?.discount) || 0);
          dateRef.current = response.quote?.quoteDate;
          setSubject(response.quote?.subject);
          setDepositPercent(parseFloat(response.quote?.deposit).toFixed(2));
          setComment(response.quote?.comment);
          setInterlocutors(response.listInterlocutors);
          setContact(response.quote?.contact);
        })
        .catch((error) => {
          console.log(error);
        });
      getApiContext.getContactsList().then((response) => {
        const contacts = response.listContacts;
        for (let i = 0; i < contacts.length; i++) {
          if (contacts[i].id === appContext.contactId) {
            setContact(contacts[i]);
          }
        }
      });
    } else {
      refRef.current.value = "DEV" + format(new Date(), "dMyyms");
      getApiContext?.getQuote(id).then((response) => {
        console.log(response);
        setListUnit(response.listUnit);
        if (newListTva.length < 1) {
          setListTva(response.listTva);
        }
        setQuote(response.quote);
        setInterlocutors(response.listInterlocutors);
      });
    }
  }, [appContext.contactId, getApiContext, id, newListTva.length, refresh]);

  const updateQuote = useCallback(
    (
      updatedGroup = undefined,
      newDiscount = undefined,
      newDepositPercent = undefined,
      updatedQuote = undefined,
      estimatedHours = undefined,
      hourlyCosts = undefined,
      purchaseCosts = undefined,
      fixedCosts = undefined,
      variableCosts = undefined
    ) => {
      setQuote((quote) => {
        let newQuote =
          updatedQuote !== undefined ? { ...updatedQuote } : { ...quote };

        const newGroup = [...newQuote.groups];
        if (updatedGroup) {
          newGroup[updatedGroup.index] = updatedGroup;
          newQuote = { ...newQuote, groups: newGroup };
        }
        let subtotalHT = 0;
        newGroup.forEach((grp) => {
          let lineTotalHT = 0;
          grp.lines.forEach((line) => {
            lineTotalHT =
              lineTotalHT + parseFloat(line.quantity * line.unitPrice);
          });
          grp.totalHT = lineTotalHT;
          subtotalHT = subtotalHT + lineTotalHT;
        });
        setSubTotalHT(subtotalHT);
        let newdiscount = newDiscount ?? discount;
        let newdiscountPrice = subtotalHT * (newdiscount / 100);
        setDiscount(newdiscount);
        setDiscountPrice(newdiscountPrice);

        let totalht = subtotalHT.toFixed(2) - newdiscountPrice.toFixed(2);
        setTotalHT(totalht);
        setMargin((margin) => {
          return { ...margin, totalHT: totalht };
        });

        let newListTva = [...listTva];

        for (let k = 0; k < newListTva.length; k++) {
          newListTva[k].value = 0;
        }
        newQuote.groups.forEach((grp, i) => {
          grp.lines.forEach((line, j) => {
            for (let k = 0; k < newListTva.length; k++) {
              if (newListTva[k].id === line.vatId) {
                let lineTva =
                  ((parseFloat(line.quantity) * parseFloat(line.unitPrice) -
                    parseFloat(line.quantity) *
                      parseFloat(line.unitPrice) *
                      (newdiscount / 100)) *
                    newListTva[k].rate) /
                  100;
                newListTva[k].value = newListTva[k].value + lineTva;
              }
            }
          });
        });
        setNewListTva(newListTva);
        let amountTotalTva = 0;
        for (let k = 0; k < newListTva.length; k++) {
          amountTotalTva += newListTva[k].value;
        }
        let totalttc =
          parseFloat(totalht.toFixed(2)) +
          parseFloat(amountTotalTva.toFixed(2));
        setTotalTTC(totalttc);

        setDepositPercent(newDepositPercent);
        setDepositPrice(
          newDepositPercent !== undefined
            ? (totalttc * newDepositPercent) / 100
            : (totalttc * depositPercent) / 100 || 0
        );
        return newQuote;
      });
    },
    [listTva, discount, depositPercent]
  );

  const updateMargin = useCallback((field, value) => {
    setMargin((margin) => {
      return { ...margin, [field]: value };
    });
  }, []);

  const onValidQuote = async (e, path) => {
    e?.preventDefault();
    console.log("valid quote");

    const values = {
      ref: refRef.current.value,
      destinataire: contact?.id,
      date_emission: dateRef.current,
      titre: subject,
      interlocuteur: quote?.userId,
      Hremise1: discount,
      Hacompte: depositPercent,
      commentaire: comment,
      heuresTotFact: margin?.estimatedHours,
      tauxHoraireMoyen: margin?.averageHourlyRateHT,
      coutsAchat: margin?.purchaseCostsHT,
      fraisFixes: margin?.fixedCosts,
      fraisVariables: margin?.variableCosts,
      Htotalht1: totalHT,
      Htotalttc: totalTTC,
      groups: quote?.groups,
      createMargin: path.includes("margin") ? 1 : 0,
    };

    console.log(values);

    let quoteId = pathName === "quoteDup" ? "" : id;
    postApiContext
      .postQuote(values, quoteId)
      .then((response) => {
        console.log(response);
        if (response && response.margin && path.includes("margin")) {
          path = path + (response.margin.id ?? "");
        }
        navigate(path);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChangeInput = (e) => {
    let value = e.target.value;
    if (e.target.name === "discount") {
      updateQuote(null, value, null);
      setDiscount(value);
    } else if (e.target.name === "deposit") {
      setDepositPercent(value);
      updateQuote(null, null, value);
    }
  };

  const formatInput = (e) => {
    if (e.target.name === "discount") {
      var newDiscount = e.target.value;
      newDiscount = parseFloat(
        parseFloat(newDiscount.replace(",", ".")).toFixed(2)
      );
      if (isNaN(newDiscount)) {
        newDiscount = "0.00";
      } else {
        newDiscount = parseFloat(newDiscount).toFixed(2);
      }
      setDiscount(newDiscount);
    } else if (e.target.name === "deposit") {
      var newDeposit = e.target.value;
      newDeposit = parseFloat(
        parseFloat(newDeposit.replace(",", ".")).toFixed(2)
      );
      if (isNaN(newDeposit)) {
        newDeposit = "0.00";
      } else {
        newDeposit = parseFloat(newDeposit).toFixed(2);
      }
      setDepositPercent(newDeposit);
    }
  };

  const addGrp = () => {
    let newQuote = { ...quote };
    let id = generateId();
    newQuote.groups.push({
      id: id,
      quoteId: 0,
      title: "",
      totalHT: 0,
      numero: parseInt(quote.groups.length) + 1 || 1,
      lines: [
        {
          id: "New0",
          designation: "",
          unit: "",
          quantity: "1.00",
          unitPrice: "0.00",
          tva: 8,
          totalHT: "0.00",
          unitId: 1,
          numero: 1,
        },
      ],
    });
    setQuote(newQuote);
    updateQuote();
  };

  function removeGroup(index) {
    let newQuote = { ...quote };
    const filteredGroups = newQuote.groups.filter((grp) => grp.id !== index);
    for (let i = index; i < newQuote.groups.length; i++) {
      newQuote.groups[i].numero = newQuote.groups[i].numero
        ? newQuote.groups[i].numero - 1
        : null;
    }
    newQuote.groups = filteredGroups;
    console.log("old quote", quote);
    console.log("index", index);

    console.log("new quote", newQuote);

    setQuote(newQuote);
  }

  const generateId = () => {
    let prefixIdLine = "New";
    let newIdLig = 0;

    for (const key in quote?.groups) {
      if (Object.hasOwnProperty.call(quote?.groups, key)) {
        const grp = quote?.groups[key];
        while (grp.id === prefixIdLine + newIdLig) {
          newIdLig += 1;
        }
      }
    }

    return prefixIdLine + newIdLig;
  };

  const transferQuoteToInvoice = (e) => {
    console.log(e.target.parentElement.id);
    onValidQuote(e, "");
    getApiContext?.quoteDepositsPaid(id).then((response) => {
      if (response?.isQuoteDepositsPaid) {
        getApiContext?.transferQuoteToInvoice(id).then((response) => {
          console.log(response);
          navigate("/invoicesList/invoice/" + response.invoice?.id);
        });
      } else {
        alert(
          "Le devis n'est pas intégralement payé, veuillez vérifier les acomptes"
        );
      }
    });
  };

  const handleClickModal = () => {
    contactPopContext.setShowContactPopup(true);
  };

  const handleSelectedContact = (index) => {
    const selectedContact = contacts?.find((contact) => contact.id === index);
    setContact(selectedContact);
    contactPopContext.setShowContactPopup(false);
  };

  function openCatalogues(index, grpIndex) {
    console.log(index, grpIndex);

    setShowCatalogues(true);
    setGrpIndexSelected(grpIndex);
    setLineIndexSelected(index);
    setLineSelected(quote?.groups[grpIndex]?.lines[index]);
    document.getElementById("hidden-scroll")?.classList.add("hidden-scroll");
  }

  function handleSelectedArticle(article) {
    let item = article;
    setShowCatalogues(false);
    setQuote((quote) => {
      let newQuote = { ...quote };
      let newLine = { ...lineSelected };
      newLine.name = item.name;
      newLine.quantity = 1;
      newLine.unitPrice = item.basePrice;
      newLine.itemId = item.id;
      newQuote.groups[grpIndexSelected].lines[lineIndexSelected] = newLine;
      console.log(newQuote);
      //Mettre à jour le groupe dans le quote

      return newQuote;
    });
    setTimeout(() => {
      updateQuote();
    }, 0);
    document.getElementById("hidden-scroll")?.classList.remove("hidden-scroll");
  }

  const onChangeInterlocutor = (e) => {
    let newInterlocutor = e.target.value;
    let newQuote = { ...quote, userId: newInterlocutor };
    setQuote(newQuote);
  };

  const handleMoveGroup = (index, direction) => {
    index = parseInt(index);
    let newQuote = { ...quote };
    let groups = newQuote.groups;
    if (direction === "up" && index > 1) {
      const lineTargetIndex = groups.findIndex(
        (group) => parseInt(group.numero) === index
      );
      const lineBeforeIndex = groups.findIndex(
        (group) => parseInt(group.numero) === index - 1
      );
      console.log(lineTargetIndex, lineBeforeIndex);
      if (lineTargetIndex !== -1 && lineBeforeIndex !== -1) {
        const newGroups = [...groups];
        newGroups[lineBeforeIndex].numero = index;
        newGroups[lineTargetIndex].numero = index - 1;
      }
    } else if (direction === "down" && index < groups.length) {
      const lineTargetIndex = groups.findIndex(
        (group) => parseInt(group.numero) === index
      );
      const nextIndex = index + 1;
      const lineAfterIndex = groups.findIndex(
        (group) => parseInt(group.numero) === nextIndex
      );
      console.log(lineTargetIndex, lineAfterIndex);
      if (lineTargetIndex !== -1 && lineAfterIndex !== -1) {
        const newGroups = [...groups];
        newGroups[lineAfterIndex].numero = index;
        newGroups[lineTargetIndex].numero = nextIndex;
      }
    }
    setQuote(newQuote);
  };

  function duplicate() {
    onValidQuote(null, "");
    getApiContext.getQuote("", quote?.id).then((response) => {
      console.log(response);
      let newQuote = response.quote;
      navigate("/quotesList/quote/" + newQuote.id);
    });
  }

  const handlePDF = async (id, isPrinting) => {
    try {
      let fileURL = await getPDF(id); // Attendez l'URL générée
      console.log("fileURL", fileURL);

      if (!fileURL) {
        console.error("Impossible de récupérer l'URL du PDF.");
        setLoading(false);
        return;
      }

      const newTab = window.open(fileURL);

      if (newTab) {
        // Attendre que le fichier soit chargé, puis imprimer
        if (isPrinting) {
          newTab.onload = () => {
            newTab.print();
          };
        }
      } else {
        setLoading(false);
        console.error(
          "Impossible d'ouvrir un nouvel onglet. Vérifiez les bloqueurs de pop-up."
        );
      }
    } catch (error) {
      console.error("Erreur lors de l'impression du PDF :", error);
      setLoading(false);
    }
  };

  const getPDF = async (id) => {
    setLoading(true);
    try {
      const response = await getApiContext.getQuotePDF(id); // Attendez la réponse de l'API
      console.log("response", response);

      if (!response || !response.data) {
        console.error("Aucune donnée PDF reçue.");
        setLoading(false);
        return null;
      }

      // Convertir la réponse en Blob
      const file = new Blob([response.data], { type: "application/pdf" });
      // Créer une URL temporaire pour le Blob
      const fileURL = URL.createObjectURL(file);
      setLoading(false);
      return fileURL;
    } catch (error) {
      console.error("Erreur lors de la récupération du PDF :", error);
      setLoading(false);
      return null;
    }
  };

  // const changeAllTva = (i) => {
  //   switch (i) {
  //     case 1:
  //       setAllTva(3);
  //       break;
  //     case 2:
  //       setAllTva(5);
  //       break;
  //     case 3:
  //       setAllTva(2);
  //       break;
  //     case 4:
  //       setAllTva(1);
  //       break;
  //     default:
  //       setAllTva(8);
  //       break;
  //   }
  // };

  const deleteDeposit = (id) => {
    deleteApiContext.deleteItem("c-deactive-deposit", id).then(() => {
      let newLinkDeposit = [...linkDeposit];
      let index = newLinkDeposit.findIndex((deposit) => deposit.id === id);
      newLinkDeposit.splice(index, 1);
      setLinkDeposit(newLinkDeposit);
    });
  };

  const headerButtonsList = {
    dropdown: {
      telecharger: {
        content: "Télécharger",
        handleClick: () => handlePDF(quote?.id),
        redir: false,
      },
      imprimer: {
        content: "Imprimer",
        handleClick: () => handlePDF(quote?.id, true),
        redir: false,
      },
      envoyer: {
        content: "Envoyer",
        handleClick: () => {
          appContext.setShowMailPop(true);
        },
        redir: false,
      },
      dupliquer: {
        content: "Dupliquer",
        handleClick: () => duplicate(),
        redir: false,
      },
      acompte: {
        content: "Créer un acompte",
        handleClick: (e) => createDeposit(e),
        redir: false,
      },
      transferer: {
        content: "Transférer",
        handleClick: transferQuoteToInvoice,
        redir: false,
      },
    },
    mainButton: {
      buttonIcon: "sauvegarder",
      buttonText: id ? "Sauvegarder" : "Créer le devis",
      buttonAction: (e) => onValidQuote(e, "/quotesList"),
    },
  };

  async function createDeposit(e) {
    e.preventDefault();
    const values = {
      idQuote: id,
      ref: linkDeposit.length + 1,
      depositDate: new Date(),
    };

    try {
      const response = await postApiContext.postDeposit(values);
      console.log(response.data.deposit.id); // L'ID est bien disponible ici
      return response.data.deposit.id; // Retourne l'ID
    } catch (error) {
      console.log(error);
      throw error; // Facultatif : relancer l'erreur pour la gestion en amont
    }
  }

  return (
    <>
      {loading && <Loader />}
      {showCatalogues && (
        <CatalogPop
          setShowCatalogues={setShowCatalogues}
          handleSelectedArticle={handleSelectedArticle}
        />
      )}
      <MailPop endpoint={"c-quote-send-email"} id={quote?.id} />
      <EditingMainHeader
        roleId={roleId}
        headerTitle="DEVIS N°"
        dateRef={dateRef}
        setShowCalendar={setShowCalendar}
        showCalendar={showCalendar}
        destination={"/quotesList"}
        endPoint={"c-deactive-quote"}
        id={id}
        refRef={refRef}
        defaultRef={ref}
        headerButtonsList={headerButtonsList}
        backRedirect="/quotesList"
      />
      <div className="listPage">
        <div className="block">
          <div>
            <QuoteInfos
              quote={quote}
              subject={subject}
              setSubject={setSubject}
              interlocutors={interlocutors}
              contact={contact}
              setContact={setContact}
              handleClickModal={handleClickModal}
              onChangeInterlocutor={onChangeInterlocutor}
              onSelectContact={handleSelectedContact}
              setContacts={setContacts}
            />
            <div className="contract-body quoteGroup">
              <div className="quoteGroup__titleContainer">
                <h2 className="quoteGroup__titleContainer_title">
                  Description du produit
                </h2>
                <hr className="quoteGroup__titleContainer_line" />
              </div>
              <div className="container">
                {quote?.groups
                  ?.sort((a, b) => a.numero - b.numero)
                  .map((group, i) => {
                    return (
                      <div key={i}>
                        <QuoteGroup
                          key={i}
                          index={i}
                          group={group}
                          id={group.id}
                          numero={i + 1}
                          lines={group.lines}
                          listTva={listTva}
                          // allTva={allTva}
                          listUnit={listUnit}
                          quoteId={quote?.id}
                          updateQuote={updateQuote}
                          openCatalogues={openCatalogues}
                          removeGroup={removeGroup}
                          changeOrderGroup={handleMoveGroup}
                          quote={quote}
                          refresh={refresh}
                          setRefresh={setRefresh}
                        />
                        <hr className="invoiceFormContainer__groupsContainer_line" />
                      </div>
                    );
                  })}
                <button
                  onClick={addGrp}
                  className="invoiceFormContainer__groupsContainer_addGroupButton"
                >
                  Ajouter un groupe{" "}
                  <FontAwesomeIcon
                    className="invoiceFormContainer__groupsContainer_addGroupButton_icon"
                    icon={faPlus}
                  />
                </button>
              </div>
              <div className="comment">
                <h3>COMMENTAIRE AJOUTÉ AU DEVIS</h3>
                <hr className="comment line" />
                <textarea
                  placeholder="Description"
                  value={comment || ""}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="aside">
            <PriceRecap
              totalTtc={totalTTC}
              totalHt={totalHT}
              discount={discount}
              discountPrice={discountPrice}
              handleDiscountChange={onChangeInput}
              handleDiscountBlur={formatInput}
              totalInvoice={subTotalHT}
              vatList={newListTva}
            />
            <div className="payment">
              <h3>MODALITÉS DE PAIEMENT</h3>
              <p>Acompte</p>
              <div>
                <input
                  type="text"
                  placeholder="0,00"
                  name="deposit"
                  value={depositPercent || 0.0}
                  onChange={onChangeInput}
                  onBlur={formatInput}
                />
                <p>% soit {parseFloat(depositPrice).toFixed(2)}€ TTC</p>
              </div>
            </div>
            <LinkedDepositList
              parentPath={`/quotesList/quote/${quote.id}`}
              linkDeposit={linkDeposit}
              deleteDeposit={deleteDeposit}
              quote={quote}
              onSubmit={onValidQuote}
              createDeposit={createDeposit}
              setRefresh={setRefresh}
            />
            <MarginCreator
              updateObject={updateQuote}
              updateMargin={updateMargin}
              object={quote}
              margin={margin}
              onValidObject={onValidQuote}
              parent={"quote"}
            />
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Quote;
