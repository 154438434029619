import BasicInput from "../../../addOns/inputComponents/basicInput";
import MainButton from "../../../addOns/buttonComponent/MainButton";
import {useContext} from "react";
import {AppContext} from "../../../../context/appContext";

export default function ItemPopForm(props) {

    let { handleEditItemSubmit, itemCopy, dataLists, setters, refs } = props;

    let {brandRef, familyRef, subfamilyRef, refDistributorRef, refMakerRef, packageRef, netPriceRef, basePriceRef, catalogRef} = refs;
    let {setCatalogSelected, setFamilySelected, setSubfamilySelected, setBrandSelected, setSupplierSelected} = setters;
    let {personalCatalogList, brandList, familyList, subfamilyList, supplierList} = dataLists;

    const appContext = useContext(AppContext);

    console.log(itemCopy)
    
    return (
        <>
            <form action="submit" className="form" onSubmit={(e) => handleEditItemSubmit(e)}>
                <div className="container">
                    <div className="content">

                        <h3>Catégories</h3>

                        <div className="select">
                            <select onChange={(e) => setCatalogSelected(e.target.value)} name="catalog"
                                    id="catalog">
                                <option
                                    value={itemCopy?.personalCatalogId}>{itemCopy?.personalCatalogId ? itemCopy?.catalog : "Choisir un catalogue"}</option>
                                {
                                    personalCatalogList && personalCatalogList?.map((element, index) => {
                                        console.log();
                                        return (
                                            <option value={element.id} key={index}>
                                                {element.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <BasicInput placeholder={"Créer un catalogue"} refs={catalogRef}/>
                        </div>
                        <div className="select">
                            <select onChange={(e) => setBrandSelected(e.target.value)} name="catalog" id="catalog">
                                <option
                                    value={itemCopy?.brand}>{itemCopy?.brand ? itemCopy?.brand : "Choisir une marque"}</option>
                                {
                                    brandList?.map((element, index) => {
                                        console.log();
                                        return (
                                            <option value={element.brand} key={index}>
                                                {element.brand}</option>
                                        )
                                    })
                                }
                            </select>
                            <BasicInput placeholder={"Créer une marque"} refs={brandRef}/>
                        </div>
                        <div className="select">
                            <select onChange={(e) => setFamilySelected(e.target.value)} name="catalog" id="catalog">
                                <option
                                    value={itemCopy?.family}>{itemCopy?.family ? itemCopy?.family : "Choisir une famille"}</option>
                                {
                                    familyList?.map((element, index) => {
                                        console.log();
                                        return (
                                            <option value={element.family} key={index}>
                                                {element.family}</option>
                                        )
                                    })
                                }
                            </select>
                            <BasicInput placeholder={"Créer une famille"} refs={familyRef}/>
                        </div>
                        <div className="select">
                            <select onChange={(e) => setSubfamilySelected(e.target.value)} name="catalog"
                                    id="catalog">
                                <option
                                    value={itemCopy?.subfamily}>{itemCopy?.subfamily ? itemCopy?.subfamily : "Choisir une sous famille"}</option>
                                {
                                    subfamilyList?.map((element, index) => {
                                        console.log();
                                        return (
                                            <option value={element.subfamily} key={index}>
                                                {element.subfamily}</option>
                                        )
                                    })
                                }
                            </select>
                            <BasicInput placeholder={"Créer une sous famille"} refs={subfamilyRef}/>
                        </div>
                        <div className="select">
                            <select onChange={(e) => setSupplierSelected(e.target.value)} name="catalog"
                                    id="catalog">
                                <option
                                    value={itemCopy?.supplier}>{itemCopy?.supplier ? itemCopy?.supplier : "Choisir un fournisseur"}</option>
                                {
                                    supplierList?.map((element, index) => {
                                        console.log();
                                        return (
                                            <option value={element.supplier} key={index}>
                                                {element.supplier}</option>
                                        )
                                    })
                                }
                            </select>
                            <MainButton buttonText={"Créer un fournisseur"} className={"CreateItemSupplierButton"}
                                        buttonAction={(e) => {
                                            e.preventDefault();
                                            appContext.setRedirection("/catalogList");
                                            appContext.setShowContactPopup(true)
                                        }}/>
                        </div>
                        {/* <div className="select">
                            <select onChange={(e) => setUnitSelected(e.target.value)} name="catalog" id="catalog">
                                <option
                                    value={itemCopy?.auPrice}>{itemCopy?.auPrice ? itemCopy?.auPrice : "Choisir une unitée"}</option>
                                {
                                    unitList?.map((element, index) => {
                                        console.log();
                                        return (
                                            <option value={element.unit_id} key={index}>
                                                {element.unit_name}</option>
                                        )
                                    })
                                }
                            </select>
                            <BasicInput className={"invisible"}/>
                        </div> */}
                    </div>
                    <div className={"content-block"}>
                        <div className="content">
                            <BasicInput refs={refDistributorRef} defaultValue={itemCopy?.refDistributor} labelName={"ref distributeur"}/>
                            <BasicInput refs={refMakerRef} defaultValue={itemCopy?.refMaker} labelName={"ref fabriquant"}/>
                            <BasicInput refs={packageRef} defaultValue={itemCopy?.package} labelName={"Conditionnement"}/>
                        </div>
                        <div className="content">
                            <BasicInput refs={netPriceRef} defaultValue={itemCopy?.netPrice} labelName={"Prix d'achat"}/>
                            <BasicInput refs={basePriceRef} defaultValue={itemCopy?.basePrice} labelName={"Prix de vente"}/>
                        </div>
                    </div>

                </div>
            </form>
        </>
    )
}   