import CataloguesList from "../../../routes/catalog/CataloguesList";

export default function CatalogPop({
  setShowCatalogues,
  handleSelectedArticle,
}) {
  return (
    <>
      <div className="bluredDiv" onClick={() => setShowCatalogues(false)}></div>
      <div
        style={{
          top: "0",
          left: "250px",
          width: "calc(90% - 250px)",
          height: "90vh",
          backgroundColor: "#fff",
          zIndex: 99999,
          position: "fixed",
          margin: "5vh 5%",
          overflow: "auto",
        }}
      >
        <CataloguesList
          isPopup={true}
          onSelectArticle={handleSelectedArticle}
        />
      </div>
      {/* </div> */}
    </>
  );
}
