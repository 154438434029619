import React from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import info_bubble_white from "../../../assets/images/picto/info_bubble_white.svg";
import modifier_blanc from "../../../assets/images/picto/modifier_blanc.svg";

export default function WelcomePopUp({ setShowWelcomePop }) {
  const navigate = useNavigate();
  return (
    <section className="welcome-pop">
      <div className="welcome-pop__content">
        <div
          className="welcome-pop__content_close-button_container"
          onClick={() => setShowWelcomePop(false)}
        >
          <FontAwesomeIcon
            className="welcome-pop__content_close-button_container_button"
            icon={faXmark}
          />
        </div>
        <h2 className="welcome-pop__content_title">
          BIENVENUE SUR CAP&nbsp;BOX
        </h2>
        <div className="welcome-pop__content_container">
          <p className="welcome-pop__content_container_description">
            Solution de gestion pour les TPE, associations, professions
            libérales. Véritable copilote de votre activité, CAP BOX intègre
            toutes les fonctionnalités nécessaires au fonctionnement de votre
            entreprise (tableau de bord, gestion des marges, base de données,
            comparateur de prix).
          </p>
          <p className="welcome-pop__content_container_important">
            <u>Important</u> :<br /> Il est important de bien remplir l'espace
            de paramétrage de votre compte afin d'avoir des documents établis en
            bonne et due forme.
          </p>
          <div className="welcome-pop__content_container_button-container">
            <button
              onClick={() => navigate("/settings")}
              className="welcome-pop__content_container_button-container_settings-button"
            >
              RÉGLER LES PARAMÈTRES <img src={modifier_blanc} alt={""} />
            </button>
            <button
              className="welcome-pop__content_container_button-container_user-guide-button"
              onClick={() =>
                window.open(
                  "https://capdevis.fr/docs/notice_utilisation_cap_box.pdf",
                  "_blank"
                )
              }
            >
              GUIDE UTILISATEUR <img src={info_bubble_white} alt={""} />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
