import { useState } from "react";
import BasicInput from "../inputComponents/basicInput";
import MainButton from "../buttonComponent/MainButton";
import close_cross from "../../../assets/images/picto/blue_cross.svg";
import magnifier_picto from "../../../assets/images/picto/magnifier_picto.svg";
import eraser_picto from "../../../assets/images/picto/eraser_picto.svg";
import BasicSelect from "../selectComponents/basicSelect";
import { DateRangePicker } from "rsuite";
import "rsuite/DateRangePicker/styles/index.css";

export default function SearchComponent({
  refresh,
  onSearchSelectChange,
  onSearchSubmit,
  contentObject,
  interlocutor,
  selectList,
  selectDefaultOption,
  dateRange,
  setDateRange,
  title,
  defaultShowFilter = false,
  className,
  setListRefresh,
}) {
  const [showFilter, setShowFilter] = useState(defaultShowFilter);

  const getArticle = (word) => {
    const feminineWords = ["facture", "marge", "dépense"];
    return feminineWords.includes(word.toLowerCase()) ? "UNE" : "UN";
  };

  return (
    <>
      <div className={"searchFilter " + className}>
        <h3
          onClick={() => setShowFilter(!showFilter)}
          className={"searchFilter__title"}
        >
          <span className="searchFilter__title_titleSpan">
            <img
              className="searchFilter__title_titleSpan_loopIcon"
              src={magnifier_picto}
              alt="loupe"
            />
            {title
              ? title
              : `RECHERCHER ${getArticle(contentObject?.ContentName)} ${
                  contentObject?.ContentName
                }`}
          </span>
          <img
            className={`searchFilter__title_plusIcon ${
              showFilter && "iconRotate"
            }`}
            src={close_cross}
            alt="+"
          />
        </h3>

        <form
          id="searchFilterForm"
          className={`searchFilter__searchFilterForm ${showFilter && "active"}`}
          onSubmit={onSearchSubmit}
        >
          <div className="searchFilter__searchFilterForm_inputContent">
            {contentObject &&
              showFilter &&
              contentObject?.Content.map((e, index) => {
                return e.type === "select" ? (
                  <BasicSelect
                    key={index}
                    action={onSearchSelectChange}
                    defaultOptionName={e.title}
                    options={e.options}
                    selectValue={e.value}
                    name={e.name}
                  />
                ) : e.type === "text" ? (
                  <BasicInput
                    name={e.name}
                    placeholder={e.title}
                    refs={e.ref}
                    key={index}
                    type={e.type}
                    labelName={e.labelName}
                  />
                ) : e.type === "dateRangePicker" ? (
                  <DateRangePicker
                    key={index}
                    className="custom-date-range-picker basicInputContainer"
                    placeholder="Date de début-fin"
                    format="dd/MM/yyyy"
                    character=" – "
                    showOneCalendar
                    ranges={[]}
                    value={dateRange}
                    isoWeek
                    onChange={(range) => {
                      setDateRange(range);
                    }}
                    locale={{
                      sunday: "dim",
                      monday: "lun",
                      tuesday: "mar",
                      wednesday: "mer",
                      thursday: "jeu",
                      friday: "ven",
                      saturday: "sam",
                      january: "jan",
                    }}
                  />
                ) : null;
              })}
            {interlocutor && showFilter && (
              <BasicSelect
                action={onSearchSelectChange}
                defaultOptionName={"Suivi par"}
                options={interlocutor}
                selectClassName={"hoverable"}
              />
            )}
            <div className="searchFilter__searchFilterActions">
              <MainButton
                className={"searchFilter__searchFilterActions_button"}
                buttonText={"Valider"}
              />
              <div
                className="searchFilter__searchFilterActions_eraser-block"
                onClick={refresh}
              >
                <img
                  className="searchFilter__searchFilterActions_eraser-block_eraser"
                  src={eraser_picto}
                  alt="gomme"
                />
                <span className="searchFilter__searchFilterActions_eraser-block_text">
                  Effacer les filtres
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
