import Paper from "@mui/material/Paper";
import React, { useEffect, useRef, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import down_arrow_select from "../../../assets/images/picto/down_arrow_select.svg";

// Fonction pour formater les légendes
function formatLegend(value) {
  const legendMap = {
    cashedLastN: "Encaissé n-1",
    forecastLastN: "Prévisionnel n-1",
    cashed: "Encaissé année courante",
    forecast: "Prévisionnel année courante",
    expenses: "Dépenses",
  };
  return legendMap[value];
}

// Légende personnalisée
function CustomLegend({ payload }) {
  return (
    <ul
      style={{
        listStyleType: "none",
        padding: 0,
        display: "flex", // Utilisation de flex pour la disposition
        flexWrap: "wrap", // Permet de passer à la ligne quand nécessaire
        gap: "10px", // Espacement entre les éléments (optionnel)
        alignItems: "center", // Aligne les éléments verticalement
        margin: 0,
        justifyContent: "center",
      }}
    >
      {payload.map((entry, index) => {
        let icon;
        const isFirst = index === 0; // Vérifie si c'est la première entrée
        if (entry.dataKey === "expenses") {
          // Représentation par une ligne pour 'expenses'
          icon = (
            <div
              style={{
                width: "30px",
                height: "2px",
                backgroundColor: entry.color,
                marginRight: "8px",
              }}
            />
          );
        } else {
          // Représentation par un cercle pour les autres
          icon = (
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: entry.color,
                borderRadius: "50%",
                marginRight: "8px",
                border: isFirst ? "0.5px solid black" : "none", // Bord noir pour la première pastille (la blanche)
              }}
            />
          );
        }

        return (
          <li
            key={`item-${index}`}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "4px",
            }}
          >
            {icon}
            <span style={{ fontSize: "14px" }}>
              {formatLegend(entry.value)}
            </span>
          </li>
        );
      })}
    </ul>
  );
}

export default function DashboardContent(props) {
  const [data, setData] = useState([]);
  const [startPeriodYear, setStartPeriodYear] = useState(null);
  const [finishPeriodYear, setFinishPeriodYear] = useState(null);
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [barSize, setBarSize] = useState(20);

  useEffect(() => {
    // console.log("props", props);
    // Mise à jour de la largeur dynamique du conteneur
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial call to set the width

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [props]);

  useEffect(() => {
    const {
      sumPerMonth = {},
      sumPerMonthPreviousY = {},
      sumOrderPerMonth = {},
      startPeriodYear,
      finishPeriodYear,
    } = props;

    // Convertir les chaînes de dates de début et de fin en objets Date
    const startDate = new Date(startPeriodYear);
    const finishDate = new Date(finishPeriodYear);

    setStartPeriodYear(startDate);
    setFinishPeriodYear(finishDate);

    const moisNoms = [
      "Jan",
      "Fév",
      "Mar",
      "Avr",
      "Mai",
      "Jun",
      "Jul",
      "Aoû",
      "Sep",
      "Oct",
      "Nov",
      "Déc",
    ];

    const moisNomsComplets = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    const transformedData = [];
    let currentDateIterator = new Date(startDate);

    while (currentDateIterator <= finishDate) {
      const year = currentDateIterator.getFullYear();
      const month = currentDateIterator.getMonth() + 1;
      const monthName = moisNoms[month - 1];
      const monthFullName = moisNomsComplets[month - 1];

      const currentMonthKey = `${year}-${month}`;
      const previousYearMonthKey = `${year - 1}-${month}`;

      const currentMonthData =
        sumPerMonth?.sumPerMonth?.[currentMonthKey] || {};
      const previousYearMonthData =
        sumPerMonthPreviousY?.sumPerMonthPreviousY?.[previousYearMonthKey] ||
        {};
      const expensesMonthData =
        sumOrderPerMonth?.sumOrderPerMonth?.[currentMonthKey] || {};

      transformedData.push({
        name: monthName,
        monthFullName: monthFullName,
        cashedLastN: previousYearMonthData.cash || 0,
        forecastLastN: Math.max(0, previousYearMonthData.forecast) || 0,
        originalForecastLastN: previousYearMonthData.forecast || 0, // Stocker la valeur originale pour le Tooltip
        cashed: currentMonthData.cash || 0,
        forecast: Math.max(0, currentMonthData.forecast) || 0,
        originalForecast: currentMonthData.forecast || 0, // Stocker la valeur originale pour le Tooltip
        expenses: expensesMonthData.expenses || 0,
      });

      currentDateIterator.setMonth(currentDateIterator.getMonth() + 1);
    }

    setData(transformedData);
  }, [props]);

  const labelMap = {
    cashedLastN: "Encaissé n-1",
    forecastLastN: "Prévisionnel n-1",
    cashed: "Encaissé année courante",
    forecast: "Prévisionnel année courante",
    expenses: "Dépenses",
  };

  const startPeriodPreviousYear = startPeriodYear
    ? new Date(
        startPeriodYear.getFullYear() - 1,
        startPeriodYear.getMonth(),
        startPeriodYear.getDate()
      )
    : null;
  const finishPeriodPreviousYear = finishPeriodYear
    ? new Date(
        finishPeriodYear.getFullYear() - 1,
        finishPeriodYear.getMonth(),
        finishPeriodYear.getDate()
      )
    : null;

  useEffect(() => {
    setBarSize(
      containerWidth > 0
        ? Math.max(
            10,
            containerWidth /
              ((props?.doubleBar === true ? data.length : data.length / 2) * 3)
          )
        : 20
    );
  }, [barSize, containerWidth, data.length, props?.doubleBar]);

  const totalCashPreviousYear =
    props?.sumPerMonthPreviousY?.totalCashPeriodPreviousY || 0;
  const totalCashCurrentYear = props?.sumPerMonth?.totalCashPeriod || 0;
  const percentageDifference =
    totalCashPreviousYear !== 0
      ? (totalCashCurrentYear * 100) / totalCashPreviousYear
      : 0;

  return (
    <>
      <section className="bar-chart">
        <h2 className="bar-chart__title">Chiffre d'affaire et Dépenses</h2>
        <div className="bar-chart__filter">
          <div className="bar-chart__filter_left">
            <div className="bar-chart__filter_left_col">
              <p className="bar-chart__filter_left_col_title">
                Chiffre d'affaire de l'année
              </p>
              <div className="bar-chart__filter_left_col_select-wrapper">
                <select
                  name="CaDate"
                  id="CaDate"
                  onChange={props.handleYearChange}
                  value={props.selectedYear}
                  className="bar-chart__filter_left_col_select-wrapper_select"
                >
                  {props.generateYearOptions.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                <img
                  src={down_arrow_select}
                  alt="down_arrow_select"
                  className="bar-chart__filter_left_col_select-wrapper_select-arrow"
                />
              </div>
            </div>
            <div className="bar-chart__filter_left_col">
              <p className="bar-chart__filter_left_col_title">
                {" "}
                Mois de début de l'exercice fiscal
              </p>
              <div className="bar-chart__filter_left_col_select-wrapper">
                <select
                  name="Month"
                  id="Month"
                  onChange={props.handleMonthChange}
                  value={props.selectedMonth}
                  className="bar-chart__filter_left_col_select-wrapper_select"
                >
                  {props.generateMonthOptions.map((month, index) => (
                    <option key={index} value={month.name}>
                      {month.name}
                    </option>
                  ))}
                </select>
                <img
                  src={down_arrow_select}
                  alt="down_arrow_select"
                  className="bar-chart__filter_left_col_select-wrapper_select-arrow"
                />
              </div>
            </div>
          </div>
          <div className="bar-chart__filter_right">
            <div className="bar-chart__filter_right_col">
              <p className="bar-chart__filter_right_col_title">
                {" "}
                Type de montant{" "}
              </p>
              <p
                className={`${
                  props.rate === "HT"
                    ? "bar-chart__filter_right_col_button rate rateSelected"
                    : "bar-chart__filter_right_col_button rate"
                }`}
                onClick={props.handleChange}
              >
                HT
              </p>
              <p
                className={`${
                  props.rate === "TTC"
                    ? "bar-chart__filter_right_col_button rate rateSelected "
                    : "bar-chart__filter_right_col_button rate "
                }`}
                onClick={props.handleChange}
              >
                TTC
              </p>
            </div>
            <div className="bar-chart__filter_right_col">
              <p className="bar-chart__filter_right_col_title"> Année </p>
              <p
                className={`bar-chart__filter_right_col_button ${
                  !props?.doubleBar ? "rate rateSelected" : "rate"
                }`}
                onClick={() => props.setDoubleBar(!props.doubleBar)}
              >
                n
              </p>
              <p
                className={`bar-chart__filter_right_col_button ${
                  props?.doubleBar ? "rate rateSelected " : "rate "
                }`}
                onClick={() => props.setDoubleBar(!props.doubleBar)}
              >
                n-1
              </p>
            </div>
          </div>
        </div>
        <section className="bar-chart__content">
          <article className={"bar-chart__content_head"}>
            <section className="bar-chart__description">
              <p className="bar-chart__description_p">
                Période du{" "}
                {startPeriodYear ? startPeriodYear.toLocaleDateString() : ""} au{" "}
                {finishPeriodYear ? finishPeriodYear.toLocaleDateString() : ""}
                {props.doubleBar &&
                  ` par rapport à la période précédente du
                ${
                  startPeriodPreviousYear
                    ? startPeriodPreviousYear.toLocaleDateString()
                    : ""
                }
                au
                ${
                  finishPeriodPreviousYear
                    ? finishPeriodPreviousYear.toLocaleDateString()
                    : ""
                }`}
              </p>
            </section>
            <div className={"bar-chart__content_head_legend"}>
              <p>
                Le chiffre d'affaire facturé de l'exercice en cours est de{" "}
                <span>{totalCashCurrentYear.toFixed(2)}€</span>
              </p>
              {props.doubleBar && (
                <>
                  <p>
                    Celui de l'exercice précédent est de{" "}
                    <span>{totalCashPreviousYear.toFixed(2)}€</span>
                  </p>
                  <p>
                    <span>{percentageDifference.toFixed(2)}%</span> du chiffre
                    d'affaire facturé de l'exercice précédent.
                  </p>
                </>
              )}
            </div>
          </article>
          <Paper
            className="paper bar-chart__chart"
            elevation={3}
            ref={containerRef}
            style={{
              paddingTop: "1.5rem",
              backgroundColor: "#F4F3F3",
              boxShadow: "none",
            }}
          >
            <ResponsiveContainer width="100%" height={280}>
              <ComposedChart data={data}>
                <CartesianGrid
                  strokeDasharray="3 3"
                  stroke="#ccc"
                  vertical={false}
                />
                <XAxis
                  dataKey="name"
                  tick={{ fontSize: 12, fill: "#666" }}
                  tickLine={false}
                  axisLine={{ stroke: "#ccc" }}
                />
                <YAxis
                  tick={{ fontSize: 12, fill: "#666" }}
                  tickLine={false}
                  axisLine={{ stroke: "#ccc" }}
                />
                <Tooltip
                  contentStyle={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                  }}
                  labelStyle={{ fontWeight: "bold", color: "#333" }}
                  labelFormatter={(label, payload) =>
                    // Utiliser le champ `monthFullName` pour l'affichage
                    payload[0]?.payload?.monthFullName || label
                  }
                  formatter={(value, name, props) => {
                    // Vérifier si nous sommes sur 'forecast' ou 'forecastLastN'
                    if (name === "forecast") {
                      const totalForecast =
                        props.payload.cashed + props.payload.forecast; // Somme d'encaissé et prévisionnel pour l'année courante
                      return [
                        `${totalForecast.toFixed(2)} €`,
                        `${labelMap[name]}`,
                      ];
                    }
                    if (name === "forecastLastN") {
                      const totalForecastLastN =
                        props.payload.cashedLastN + props.payload.forecastLastN; // Somme d'encaissé et prévisionnel pour l'année précédente
                      return [
                        `${totalForecastLastN.toFixed(2)} €`,
                        `${labelMap[name]}`,
                      ];
                    }
                    // Retourner la valeur normale pour les autres barres
                    return [`${value.toFixed(2)} €`, labelMap[name] || name];
                  }}
                />

                <Legend
                  content={<CustomLegend />}
                  wrapperStyle={{ fontSize: "14px" }}
                />

                {/* Calcul dynamique de la taille de la barre */}
                {props?.doubleBar && (
                  <>
                    <Bar
                      dataKey="cashedLastN"
                      barSize={barSize}
                      fill="#FFF"
                      stackId="n-1"
                    />
                    <Bar
                      dataKey="forecastLastN"
                      barSize={barSize}
                      fill="#C4C4C4"
                      stackId="n-1"
                    />
                  </>
                )}

                {/* Barres Encaissé et Prévisionnel pour Année Courante */}
                <Bar
                  dataKey="cashed"
                  barSize={barSize}
                  fill="#FFF"
                  stackId="current"
                />
                <Bar
                  dataKey="forecast"
                  barSize={barSize}
                  fill="#FDAB0D"
                  stackId="current"
                />

                {/* Ligne pour les dépenses */}
                <Line
                  type="monotone"
                  dataKey="expenses"
                  stroke="#FDAB0D"
                  strokeWidth={1}
                  dot={{ stroke: "#FDAB0D", strokeWidth: 2, r: 4 }}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Paper>
        </section>
      </section>
    </>
  );
}
