export default function MarginBody(data) {
  return (
    <>
      {/* <p><span className="boldFont">Vous pouvez calculer vos marges brutes ici : </span>vous pouvez cependant retrouver les
                informations d'une facture en la modifiant et en cliquant sur "Calculer la marge brute"</p> */}
      <div className="invoiceGroup__titleContainer">
        <h3 className="invoiceGroup__titleContainer_title">
          % CALCUL DE LA MARGE
        </h3>
        <hr className="invoiceGroup__titleContainer_line" />
      </div>
      <div className="container__component margin-component">
        <div className="container__component_amounts margin-amounts">
          {Object.entries(data.data.form).map(([key, value]) => {
            const label = {
              estimatedHours: "Nombre d'heures prévues",
              realHours: "Nombre d'heures réelles",
              averageHourlyRateHT: "Coût horaire moyen HT",
              purchaseCostsHT: "Coûts d'achats",
              fixedCosts: "Frais fixes",
              variableCosts: "Frais variables",
              totalHT: "Montant total facture HT",
            }[key];

            return (
              <div key={key}>
                <p>{label}</p>
                <input
                  type="text"
                  name={key}
                  value={value.value}
                  onChange={(e) => value.setter(e.target.value)}
                  onBlur={(e) => {
                    e.target.value === ""
                      ? value.setter(0)
                      : value.setter(e.target.value);
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="container__component_results margin-results">
          <div>
            <p>Marge théorique</p>
            <h3>
              {data.data.results.theoreticalMargin.theoreticalMargin.toFixed(2)}{" "}
              €
            </h3>
          </div>
          <div>
            <p>% Marge théorique</p>
            <h3>
              {data.data.results.percentMargin.percentMargin.toFixed(2)} %
            </h3>
          </div>
        </div>
      </div>
    </>
  );
}
