import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ValidPop from "../../addOns/popComponents/validPop";
import { useState, useContext } from "react";
import ReactDOM from "react-dom";
import { PopupContext } from "../../../context/PopupContext";
import Deposit from "../../../routes/deposit/deposit";
import whiteBin from "../../../assets/images/icons/Picto/supprimer-blanc.svg";

export default function LinkedDepositList({
  onSubmit,
  linkDeposit,
  deleteDeposit,
  parentPath,
  createDeposit,
  setRefresh,
}) {
  const [showValidPop, setShowValidPop] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [depositId, setDepositId] = useState(null);
  const { popups, setPopupVisibility } = useContext(PopupContext);

  const handleDepositItemAdd = async (e) => {
    try {
      const depositId = await createDeposit(e); // Attendre que l'ID soit disponible
      console.log(depositId);
      setDepositId(depositId);
      setPopupVisibility("showDepositPopup", true);
    } catch (error) {
      console.log("Erreur lors de la création du dépôt :", error);
    }
  };

  const handleDeleteDeposit = (e, id) => {
    e.stopPropagation();
    setIdToDelete(id);
    setShowValidPop(true);
  };

  const confirmDelete = () => {
    deleteDeposit(idToDelete);
    setShowValidPop(false);
  };

  const buttonsObject = [
    {
      text: "Annuler",
      className: "backButton",
      action: () => setShowValidPop(false),
    },
    { text: "Supprimer", className: "continueButton", action: confirmDelete },
  ];

  return (
    <div className="linkedDepositList">
      {showValidPop && (
        <ValidPop
          bgcAction={() => setShowValidPop(false)}
          showValidPop={showValidPop}
          title={"Confirmer la suppression ?"}
          desc={"Voulez-vous vraiment supprimer cet acompte ?"}
          buttonsObject={buttonsObject}
        />
      )}
      {popups.showDepositPopup &&
        ReactDOM.createPortal(
          <>
            <div
              onClick={() => {
                setPopupVisibility("showDepositPopup", false);
                setRefresh(true);
              }}
              className="bluredDiv"
            ></div>
            <div
              style={{
                width: "90%",
                height: "90vh",
                backgroundColor: "#fff",
                zIndex: 99999,
                position: "absolute",
                margin: "5vh 5%",
                overflow: "auto",
                top: "0",
              }}
            >
              <Deposit depositId={depositId} setRefresh={setRefresh} />
            </div>
          </>,
          document.getElementsByClassName("main")[0]
        )}
      <header className={"linkedDepositList__header"}>
        <h3>ACOMPTES LIÉS</h3>
        <span className={"linkedDepositList__header_amount"}>
          <p>
            {linkDeposit
              ?.reduce(
                (acc, deposit) => acc + (parseFloat(deposit.totalTtc) || 0),
                0
              )
              .toFixed(2)}
            €
          </p>
          <div className="v-line-blue"></div>
          <p>
            {linkDeposit
              ?.reduce(
                (acc, deposit) => acc + (parseFloat(deposit.rate) || 0),
                0
              )
              .toFixed(2)}
            %
          </p>
        </span>
      </header>
      <article className={"linkedDepositList__article"}>
        {linkDeposit?.map((deposit, i) => (
          <article
            key={i}
            className={"linkedDepositList__article_item"}
            onClick={() => {
              setDepositId(deposit.id);
              setPopupVisibility("showDepositPopup", true);
            }}
          >
            <header>
              <span> N° {deposit.ref}</span>
            </header>
            <section>
              <div>
                {deposit.paymentDate && (
                  <span>
                    {deposit.paymentDate.split("-").reverse().join("/")}
                  </span>
                )}
                {deposit.paymentDate && <div className="v-line"></div>}
                <span> {deposit.totalTtc || 0} €</span>
                <div className="v-line"></div>
                <span className={"linkedDepositList__article_item_rate"}>
                  {" "}
                  {deposit.rate || 0} %
                </span>
              </div>
              <p onClick={(e) => handleDeleteDeposit(e, deposit.id)}>
                <img src={whiteBin} alt="poubelle" />
              </p>
            </section>
          </article>
        ))}
        <article
          className={"linkedDepositList__article_item addItem"}
          onClick={handleDepositItemAdd}
        >
          <p className={"addText"}>Ajouter un acompte</p>
          <FontAwesomeIcon className="addPlus" icon={faPlus} />
        </article>
      </article>
    </div>
  );
}
