import "../../../styles/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreditLine from "./CreditLine";
import { useCallback, useEffect, useState } from "react";
import {
  faChevronDown,
  faChevronUp,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import DeletButton from "../../../components/addOns/deletButton";

function CreditGroup(props) {
  let [totalHT, setTotalHT] = useState(
    props.group.totalHT ||
      props.group?.lines?.reduce(
        (acc, line) => acc + line.quantity * line.unitPrice,
        0
      ),
    "0.00"
  );

  useEffect(() => {
    setTotalHT(props.group.totalHT);
  }, [props]);

  useEffect(() => {
    // Recalculer totalHT en fonction des lignes actuelles
    const newTotalHT = props.group?.lines?.reduce(
      (acc, line) => acc + line.quantity * line.unitPrice,
      0
    );
    setTotalHT(newTotalHT);
  }, [props.group.lines]); // Écouter les changements des lignes uniquement

  const updateGroup = useCallback(
    (updatedLine, updatedGroup) => {
      let newGroup = { ...props.group };
      let newLines =
        updatedLine !== undefined ? [...newGroup.lines] : updatedGroup.lines;
      if (updatedLine !== undefined) {
        newLines[updatedLine.numero - 1] = updatedLine;
      } else {
        newLines = updatedGroup.lines;
      }
      let totalHT = 0;
      newLines?.forEach((line) => {
        totalHT += parseFloat(
          line.totalHT || line.quantity * line.unitPrice || "0"
        );
      });

      setTotalHT(totalHT);
      let newgroup;
      if (updatedLine !== undefined) {
        newgroup = {
          ...newGroup,
          totalHT: totalHT,
          index: props.index,
          creditId: props.creditId,
          lines: newLines,
        };
      } else {
        newgroup = {
          ...updatedGroup,
          totalHT: totalHT,
          index: props.index,
          creditId: props.creditId,
          lines: newLines,
        };
      }
      props.group.lines = newLines;
      props.updateCredit(newgroup, undefined, undefined, undefined);
    },
    [props]
  );

  const onChange = (e) => {
    let value = e.target.value;

    if (e.target.name === "title") {
      var newGrp = { ...props.group, title: value };
    }

    updateGroup(undefined, newGrp);
  };

  const addLine = () => {
    let newGroup = { ...props.group };
    let id = generateId();
    newGroup.lines.push({
      id: id,
      creditGroupId: props.id,
      itemId: "",
      name: "",
      numero: newGroup.lines.length + 1,
      unitId: 1,
      unitPrice: "0.00",
      quantity: "1.00",
      vatId: 8,
    });
    props.updateCredit(newGroup);
  };

  const generateId = () => {
    let prefixIdLine = "New";
    let newIdLig = 0;

    props.group.lines.forEach((line) => {
      while (line.id === prefixIdLine + newIdLig) {
        newIdLig += 1;
      }
    });
    return prefixIdLine + newIdLig;
  };

  function removeItem(index) {
    let newGroup = { ...props.group };
    newGroup.lines = props.group?.lines.filter((i) => {
      return i.id !== index;
    });
    updateGroup(undefined, newGroup);
  }

  function openCatalogues(index, grpIndex) {
    props.openCatalogues(index, grpIndex);
  }

  function changeOrderLine(index, direction) {
    let newGroup = { ...props.group };
    // console.log(index);
    if (direction === "up") {
      let line = newGroup.lines[index];
      newGroup.lines.splice(index, 1);
      newGroup.lines.splice(index - 1, 0, line);
      // console.log(newGroup.lines);
    } else {
      let line = newGroup.lines[index];
      newGroup.lines.splice(index, 1);
      newGroup.lines.splice(index + 1, 0, line);
    }
    // console.log(newGroup.lines);
    newGroup.lines.forEach((line, i) => {
      line.numero = i + 1;
    });
    updateGroup(undefined, newGroup);
  }

  const validPopContent = {
    title: "Confirmer la suppression",
    desc: `Êtes-vous sûr de vouloir supprimer le groupe ${
      props.group?.title ? `"${props.group.title}"` : ""
    } ?`,
  };

  return (
    <div className="invoiceGroup">
      <div className="invoiceGroup__groupContainer">
        <div className="invoiceGroup__groupContainer_groupHeader">
          <input
            name="title"
            onChange={(e) => onChange(e)}
            className="invoiceGroup__groupContainer_groupHeader_input"
            type="text"
            placeholder="Nom du groupe d'articles"
            value={props.group?.title || ""}
          />
          <div className="invoiceGroup__groupContainer_groupHeader_total">
            <p>
              Montant HT <span>{totalHT}</span>&nbsp;€
            </p>
            <p className="invoiceGroup__groupContainer_groupHeader_total_discount">
              Montant après remise <span>{totalHT}</span>&nbsp;€
            </p>
          </div>
          <div className="invoiceGroup__groupContainer_groupHeader_arrowGroup">
            <p
              onClick={() => {
                props.changeOrderGroup(props.group?.numero, "up");
              }}
            >
              <FontAwesomeIcon
                className="invoiceGroup__groupContainer_groupHeader_arrowGroup_arrow"
                icon={faChevronUp}
              />
            </p>
            <p
              onClick={() => {
                props.changeOrderGroup(props.group?.numero, "down");
              }}
            >
              <FontAwesomeIcon
                className="invoiceGroup__groupContainer_groupHeader_arrowGroup_arrow"
                icon={faChevronDown}
              />
            </p>
            <DeletButton
              deleteItemByNotId={props.removeGroup}
              url={"c-delete-credit-group"}
              id={props.group.id}
              className={
                "invoiceGroup__groupContainer_groupHeader_arrowGroup_deleteButton"
              }
              validPopContent={validPopContent}
              color={"red"}
              refresh={props.refresh}
              setRefresh={props.setRefresh}
            />
          </div>
        </div>

        {props.group?.lines?.map((line, i) => (
          <CreditLine
            key={line.id}
            index={i}
            line={line}
            id={line.id}
            grpId={props.id}
            grpIndex={props.index}
            numero={i}
            designation={line.name}
            unit={line.unitId}
            quantity={line.quantity}
            unitPrice={line.unitPrice}
            tva={line.vatId}
            allTva={props.allTva}
            removeItem={removeItem}
            listTva={props.listTva}
            listUnit={props.listUnit}
            updateGroup={updateGroup}
            openCatalogues={openCatalogues}
            changeOrderLine={changeOrderLine}
            credit={props.credit}
            linesLength={props.group.lines.length}
            refresh={props.refresh}
            setRefresh={props.setRefresh}
          />
        ))}

        <div className="invoiceGroup__groupContainer_groupOptions">
          <button
            className="invoiceGroup__groupContainer_addLineButton"
            onClick={addLine}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className="invoiceGroup__groupContainer_addLineButton_icon"
            />
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreditGroup;
