import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../context/appContext";
import Prospect from "../../components/customer/contacts/Prospect";
import ContactInvoiceList from "../../components/customer/contacts/contactInvoiceList";
import InvoicePopList from "../../components/customer/contacts/contactInvoiceListPop";
import ContactorderList from "../../components/customer/contacts/contactOrderList";
import OrderPopList from "../../components/customer/contacts/contactOrderListPop";
import ContactQuoteList from "../../components/customer/contacts/contactQuoteList";
import QuotePopList from "../../components/customer/contacts/contactQuoteLitsPop";
import CreateProspectPop from "../../components/customer/contacts/createProspectPop";
import EditProspect from "../../components/customer/contacts/editProspect";
import ProspectPopList from "../../components/customer/contacts/prospectPopList";

import {
  faAddressBook,
  faBuilding,
  faCheck,
  faEraser,
  faIdCard,
  faMapLocationDot,
  faArrowLeft,
  faFileMedical,
} from "@fortawesome/free-solid-svg-icons";
import { GetApiContext } from "../../context/apiCalls/getApiCalls";
import EditingMainHeader from "../../components/addOns/headers/EditingMainHeader";
import { useAuthState } from "../../context/authContext";
import { PostApiContext } from "../../context/apiCalls/postApiCalls";

export default function Contact() {
  let navigate = useNavigate();
  const appContext = useContext(AppContext);
  const getApiCalls = useContext(GetApiContext);
  const postApiCalls = useContext(PostApiContext);

  let { id } = useParams();
  const { roleId } = useAuthState();
  useEffect(() => {
    appContext.setContactId(id);
  }, [appContext, id]);

  // Ref
  const refRef = useRef("");
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const societyRef = useRef();
  const functionRef = useRef();
  const adress1Ref = useRef();
  const postalRef = useRef();
  const cityRef = useRef();
  const mobilePhoneRef = useRef();
  const landlinePhoneRef = useRef();
  const faxPhoneRef = useRef();
  const emailRef = useRef();
  const commentRef = useRef();

  // useState
  const [contact, setContact] = useState({});
  const [civilityLabel, setCivilityLabel] = useState("");
  //const [showMail, setShowMail] = useState(false);
  const [valeurSelectionnee, setValeurSelectionnee] = useState("");

  const options = useMemo(
    () => [
      { value: "1", label: "M." },
      { value: "2", label: "Mme" },
      { value: "3", label: "Mlle" },
      { value: "4", label: "M. et Mme" },
    ],
    []
  );

  const [valeurDb, setValeurDb] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const [checkboxError, setCheckboxError] = useState(false);
  const typeOptions = [
    { value: "customer", label: "Client" },
    { value: "provider", label: "Fournisseur" },
    { value: "prospect", label: "Prospect" },
  ];

  useEffect(() => {
    getApiCalls.getContact(id).then((response) => {
      setContact(response.contact);
      // console.log("log de contact", response.data);
      // setFirstName(response.data.contact.firstName);
      // console.log("log de contact", response.data.contact);
    });
  }, [getApiCalls, id]);
  const [isClicked, setIsClicked] = useState(false);

  const deletInputContent = () => {
    setIsClicked(true);

    setTimeout(() => {
      setIsClicked(false);
    }, 1000);
    setTimeout(() => {
      firstNameRef.current.value = "";
      lastNameRef.current.value = "";
      societyRef.current.value = "";
      functionRef.current.value = "";
      adress1Ref.current.value = "";
      postalRef.current.value = "";
      cityRef.current.value = "";
      mobilePhoneRef.current.value = "";
      landlinePhoneRef.current.value = "";
      faxPhoneRef.current.value = "";
      emailRef.current.value = "";
      commentRef.current.value = "";
    }, 500);
  };

  const handleFormSubmission = () => {
    //Display navigator error if form is not valid
    const form = document.getElementById("form");

    if (!typeValue && !valeurDb) {
      setCheckboxError(true);
      return;
    }

    if (form.checkValidity()) {
      form.requestSubmit(); // Soumet le formulaire si valide
    } else {
      form.reportValidity(); // Affiche les erreurs de validation
    }
  };

  const onValidContact = async (e) => {
    e.preventDefault();
    const values = {
      id: contact.id,
      ref: refRef.current.value,
      firstName: firstNameRef.current?.value,
      lastName: lastNameRef.current?.value,
      civility: valeurSelectionnee,
      society: societyRef.current?.value,
      function: functionRef.current?.value,
      adress1: adress1Ref.current?.value,
      postal: postalRef.current?.value,
      city: cityRef.current?.value,
      mobilePhone: mobilePhoneRef.current?.value,
      landlinePhone: landlinePhoneRef.current?.value,
      faxPhone: faxPhoneRef.current?.value,
      email: emailRef.current?.value,
      comment: commentRef.current?.value,
      type: typeValue ? typeValue : valeurDb,
    };
    console.log("log de value ", values);
    // ici dans values je recup bien la ref
    // console.log(id);
    postApiCalls
      .postContact(id, values)
      //await axios.post(`${process.env.REACT_APP_CONTACT_POST}${id}`, values, customHeaders)
      .then((response) => {
        if (values.type) {
          navigate(appContext.redirection || "/contactsList");
          appContext.setRedirection(null);
          appContext.setShowContactPopup(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const civilityId = contact.civilityId;
    const option = options.find((option) => option.value === civilityId);
    setCivilityLabel(option?.label);
    setValeurSelectionnee(option ? option.value : "civilité");
    setValeurDb(contact.type);
  }, [contact.civilityId, contact.type, options]);

  const [formState, setFormState] = useState({
    firstName: "Pas de prénom",
    lastName: "Pas de nom",
    society: "Pas de société",
    function: "Pas de fonction",
    civility: "Pas de civilité",
    adress1: "Pas d'adresse",
    postal: "Pas de code postal",
    city: "Pas de ville",
    mobilePhone: "Pas de mobile",
    landlinePhone: "Pas de fixe",
    faxPhone: "Pas de fax",
    email: "Pas de mail",
    type: "Pas de type",
    comment: "",
  });
  const handlChangeValue = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    setValeurDb(event.target.value);
    setTypeValue(event.target.value);
    setCheckboxError(false);
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = options.find(
      (option) => option.value === selectedValue
    );
    setCivilityLabel(selectedOption.label);

    setValeurSelectionnee(selectedValue);
  };

  /* function closeMailPop() {
        setShowMail(false);
    } */

  const [hoveredInput, setHoveredInput] = useState(null);

  const handleInputHover = (option) => {
    setHoveredInput(option);
  };

  const headerButtonsList = {
    otherButtons: [
      {
        title: "Créer un devis",
        className: "createQuoteButton",
        icon: faFileMedical,
        action: () => appContext.setShowQuotePopup(true),
        showByRoleId: true,
      },
      // Add more buttons here if needed
    ],
    mainButton: {
      buttonIcon: faArrowLeft,
      buttonText: id ? "Sauvegarder" : "Créer le contact",
      buttonAction: handleFormSubmission,
    },
  };

  // console.log("typeValue", typeValue);
  return (
    <>
      <EditingMainHeader
        defaultRef={contact?.ref}
        headerTitle="CONTACT"
        headerButtonsList={headerButtonsList}
        id={id}
        roleId={roleId}
        backRedirect={appContext.redirection || "/contactsList"}
        refRef={refRef}
      />
      {appContext.showCreateProspectPopup && <CreateProspectPop />}
      {appContext.showProspectListPopup && <ProspectPopList />}
      {appContext.showEditProspectPopup && <EditProspect />}
      {appContext.showQuotePopup && <QuotePopList />}
      {appContext.showInvoicePopup && <InvoicePopList />}
      {appContext.showOrderPopup && <OrderPopList />}
      {
        //Forcer le scroll en haut de page à cause des composents au dessus
        window.scrollTo(0, 0)
      }
      <div className={`${appContext.blur ? "blur" : ""} listPage`}>
        {/* <MailPop
                        show={showMail}
                        closeMailPop={closeMailPop}
                    /> */}
        <form id="form" onSubmit={(e) => onValidContact(e)}>
          <div className="contract">
            <div className="allContactContent">
              <div className="contactContent">
                <div>
                  <h3 className="title">INFORMATIONS CONTACT</h3>
                  <div className="blabla" onClick={deletInputContent}>
                    <p className={isClicked ? "hehe" : "erase"}>Effacer</p>
                    <div
                      className={
                        isClicked ? "icon-container clicked" : "icon-container"
                      }
                    >
                      <FontAwesomeIcon icon={faEraser} className="icon" />
                    </div>
                  </div>
                </div>
                <div className="inputs">
                  <div className="clientInfos">
                    <div className="allCheck">
                      {typeOptions.map((option) => (
                        <label className="checkBox" key={option.value}>
                          <span
                            className={`checkStyle ${
                              valeurDb === option.value ? "checked" : ""
                            } ${
                              hoveredInput &&
                              hoveredInput.value === option.value
                                ? "hovered"
                                : ""
                            }`}
                            onMouseEnter={() => handleInputHover(option)}
                            onMouseLeave={() => handleInputHover(null)}
                          >
                            {valeurDb === option.value ||
                            (hoveredInput &&
                              hoveredInput.value === option.value) ? (
                              <FontAwesomeIcon icon={faCheck} />
                            ) : null}
                          </span>
                          <input
                            className="check"
                            type="checkbox"
                            defaultValue={option.value}
                            checked={valeurDb === option.value}
                            onChange={(event) =>
                              handleCheckboxChange(event, option)
                            }
                          />
                          {option.label}
                        </label>
                      ))}
                      {checkboxError && (
                        <p className="error-message">
                          Veuillez sélectionner le type du contact.
                        </p>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        className="input "
                        defaultValue={contact.lastName}
                        name="lastName"
                        onChange={handlChangeValue}
                        required
                        ref={lastNameRef}
                        type="text"
                        placeholder="Nom*"
                      />
                      <input
                        className="input "
                        defaultValue={contact.firstName}
                        name="firstName"
                        onChange={handlChangeValue}
                        required
                        ref={firstNameRef}
                        type="text"
                        placeholder="Prenom*"
                      />
                      <select
                        className="select"
                        required
                        name="Civilite"
                        id="Civilite"
                        value={valeurSelectionnee}
                        onChange={handleChange}
                      >
                        <option className="option" value="">
                          -- Sélectionnez* --
                        </option>
                        {options.map((option) => (
                          <option
                            className="option"
                            value={option.value}
                            key={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </select>
                      <input
                        className="input "
                        type="text"
                        name="adress1"
                        required
                        onChange={handlChangeValue}
                        defaultValue={contact.adress1}
                        ref={adress1Ref}
                        placeholder="Adresse*"
                      />
                      <input
                        className="input "
                        type="text"
                        name="postal"
                        required
                        onChange={handlChangeValue}
                        defaultValue={contact.postal}
                        ref={postalRef}
                        placeholder="Code postal*"
                      />
                      <input
                        className="input "
                        type="text"
                        name="city"
                        required
                        onChange={handlChangeValue}
                        defaultValue={contact.city}
                        ref={cityRef}
                        placeholder="Ville*"
                      />
                      <input
                        className="input"
                        type="text"
                        name="society"
                        onChange={handlChangeValue}
                        defaultValue={contact.society}
                        ref={societyRef}
                        placeholder="Société"
                      />
                      <input
                        className="input"
                        type="text"
                        name="function"
                        onChange={handlChangeValue}
                        defaultValue={contact.function}
                        ref={functionRef}
                        placeholder="Fonction"
                      />
                      <input
                        className="input"
                        type="text"
                        name="mobilePhone"
                        onChange={handlChangeValue}
                        defaultValue={contact.mobilePhone}
                        ref={mobilePhoneRef}
                        placeholder="Téléphone portable"
                      />
                      <input
                        className="input"
                        type="text"
                        name="landlinePhone"
                        onChange={handlChangeValue}
                        defaultValue={contact.landlinePhone}
                        ref={landlinePhoneRef}
                        placeholder="Téléphone fixe"
                      />
                      <input
                        className="input"
                        type="text"
                        name="faxPhone"
                        onChange={handlChangeValue}
                        defaultValue={contact.faxPhone}
                        ref={faxPhoneRef}
                        placeholder="Fax"
                      />
                      <input
                        className="input"
                        type="email"
                        name="email"
                        onChange={handlChangeValue}
                        defaultValue={contact.email}
                        ref={emailRef}
                        placeholder="Email"
                      />

                      <p className="Infos">
                        *Informations obligatoires{" "}
                        <span className="underline"></span>
                      </p>
                    </div>
                  </div>
                  <div className="comment">
                    <h4 className="title">Commentaire ajouté au devis</h4>
                    <textarea
                      defaultValue={contact.comment}
                      ref={commentRef}
                      placeholder="Description"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="contactContentResum">
                <p className="title">Apercu du profil :</p>
                <div className="profileVueContent">
                  <FontAwesomeIcon className="descIcons" icon={faIdCard} />
                  <div className="infoGroup">
                    <p
                      className={firstNameRef?.current?.value ? "p" : "Nothing"}
                    >
                      <span className="profileVueSpan">Nom:</span>&nbsp;{" "}
                      {lastNameRef?.current?.value
                        ? lastNameRef?.current?.value
                        : formState.lastName}
                    </p>
                    <p
                      className={lastNameRef?.current?.value ? "p" : "Nothing"}
                    >
                      <span className="profileVueSpan">Prenom:</span>&nbsp;{" "}
                      {firstNameRef?.current?.value
                        ? firstNameRef?.current?.value
                        : formState.firstName}
                    </p>
                    <p className={civilityLabel ? "p" : "Nothing"}>
                      <span className="profileVueSpan">Civilité:</span>&nbsp;{" "}
                      {civilityLabel ? civilityLabel : formState.civility}
                    </p>
                  </div>
                  <FontAwesomeIcon
                    className="descIcons"
                    icon={faMapLocationDot}
                  />
                  <div className="infoGroup">
                    <p className={adress1Ref?.current?.value ? "p" : "Nothing"}>
                      <span className="profileVueSpan">Adesse:</span>&nbsp;{" "}
                      {adress1Ref?.current?.value
                        ? adress1Ref?.current?.value
                        : formState.adress1}{" "}
                    </p>
                    <p className={cityRef?.current?.value ? "p" : "Nothing"}>
                      <span className="profileVueSpan">Ville:</span>&nbsp;{" "}
                      {cityRef?.current?.value
                        ? cityRef?.current?.value
                        : formState.city}
                    </p>
                    <p className={postalRef?.current?.value ? "p" : "Nothing"}>
                      <span className="profileVueSpan">Code postal:</span>
                      &nbsp;{" "}
                      {postalRef?.current?.value
                        ? postalRef?.current?.value
                        : formState.postal}{" "}
                    </p>
                  </div>
                  <FontAwesomeIcon className="descIcons" icon={faBuilding} />
                  <div className="infoGroup">
                    <p className={societyRef?.current?.value ? "p" : "Nothing"}>
                      <span className="profileVueSpan">Société:</span>&nbsp;{" "}
                      {societyRef?.current?.value
                        ? societyRef?.current?.value
                        : formState.society}
                    </p>
                    <p
                      className={functionRef?.current?.value ? "p" : "Nothing"}
                    >
                      <span className="profileVueSpan">Fonction:</span>&nbsp;{" "}
                      {functionRef?.current?.value
                        ? functionRef?.current?.value
                        : formState.function}
                    </p>
                    {/* <p className={typeValue ? "p" : (contact.type ? "p" : "Nothing")}><span className='profileVueSpan'>Type:</span>&nbsp; {typeValue ? typeValue : (contact.type ? contact.type : formState.type)}</p> */}
                    <p
                      className={
                        typeValue ? "p" : contact.type ? "p" : "Nothing"
                      }
                    >
                      <span className="profileVueSpan">Type:</span>&nbsp;
                      {typeValue
                        ? typeOptions.find(
                            (option) => option.value === typeValue
                          ).label
                        : contact.type === "provider"
                        ? "fournisseur"
                        : contact.type
                        ? contact.type
                        : formState.type}
                    </p>
                  </div>
                  <FontAwesomeIcon className="descIcons" icon={faAddressBook} />
                  <div className="infoGroup">
                    <p
                      className={
                        mobilePhoneRef?.current?.value ? "p" : "Nothing"
                      }
                    >
                      <span className="profileVueSpan">Mobile:</span>&nbsp;{" "}
                      {mobilePhoneRef?.current?.value
                        ? mobilePhoneRef?.current?.value
                        : formState.mobilePhone}
                    </p>
                    <p
                      className={
                        landlinePhoneRef?.current?.value ? "p" : "Nothing"
                      }
                    >
                      <span className="profileVueSpan">Fixe:</span>&nbsp;{" "}
                      {landlinePhoneRef?.current?.value
                        ? landlinePhoneRef?.current?.value
                        : formState.landlinePhone}
                    </p>
                    <p
                      className={faxPhoneRef?.current?.value ? "p" : "Nothing"}
                    >
                      <span className="profileVueSpan">Fax:</span>&nbsp;{" "}
                      {faxPhoneRef?.current?.value
                        ? faxPhoneRef?.current?.value
                        : formState.faxPhone}
                    </p>
                    <p className={emailRef?.current?.value ? "p" : "Nothing"}>
                      <span className="profileVueSpan">Mail:</span>&nbsp;{" "}
                      {emailRef?.current?.value
                        ? emailRef?.current?.value
                        : formState.email}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="contract">
          {id && (
            <>
              <Prospect />
              <ContactQuoteList />
              <ContactInvoiceList />
              <ContactorderList />
            </>
          )}
        </div>
      </div>
    </>
  );
}
