import logo_enveloppe from "../../assets/images/picto/envelope.png";
import logo from "../../assets/images/CapBox.svg";
export default function Header({ navigate }) {
  return (
    <div className="loginHeader">
      <div className="loginHeader__logoContainer">
        <img
          onClick={(e) => navigate("/")}
          className="loginHeader__logoContainer_logo"
          src={logo}
          alt=""
        />
        <hr className="loginHeader__line" />
      </div>
      <div className="loginHeader__rightContainer">
        <figure className="loginHeader__rightContainer_iconContainer">
          <img
            className="loginHeader__rightContainer_iconContainer_icon"
            src={logo_enveloppe}
            alt=""
          />
        </figure>
      </div>
    </div>
  );
}
