import Total from "./total";
import TvaList from "./tvaList";

export default function PriceRecap({
  discount,
  handleDiscountChange,
  handleDiscountBlur,
  totalInvoice,
  vatList,
  totalHt,
  totalTtc,
  discountPrice,
}) {
  return (
    <div className="priceRecap">
      <Total
        title="Sous total HT"
        price={`${parseFloat(totalInvoice).toFixed(2)}`}
      />
      <div className="priceRecap__discount">
        <p className="priceRecap__discount_title">Remise globale</p>
        <div className="priceRecap__discount_priceContainer">
          <input
            onChange={(e) => handleDiscountChange(e)}
            name={"discount"}
            onBlur={(e) => handleDiscountBlur(e)}
            className="priceRecap__discount_priceContainer_input"
            onWheel={(e) => e.target.blur()}
            value={discount || ""}
            type="number"
            placeholder="0,00 %"
          />
          <p className="priceRecap__discount_priceContainer_price">
            {discountPrice ?? 0.0} €
          </p>
        </div>
      </div>
      <Total title="Total HT" price={parseFloat(totalHt).toFixed(2)} />
      <TvaList tvaList={vatList} />
      <Total title="Total net TTC" price={parseFloat(totalTtc).toFixed(2)} />
    </div>
  );
}
