import "../../../../styles/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import QuoteLine from "./QuoteLine";
import { useCallback, useEffect, useState } from "react";
import DeletButton from "../../../addOns/deletButton";

function QuoteGroup(props) {
  const [designation, setDesignation] = useState(props.group.title || "");
  const [totalHT, setTotalHT] = useState(props.group.totalHT || 0);

  useEffect(() => {
    setDesignation(props.group.title || "");
  }, [props.group.title]);

  useEffect(() => {
    if (props.group.totalHT) {
      setTotalHT(props.group.totalHT);
    }
  }, [props.group.totalHT]);

  const updateGroup = useCallback(
    (updatedLine, updatedGroup) => {
      let newGroup = { ...props.group };
      let newLines = undefined;
      if (updatedLine !== undefined) {
        newLines = [...newGroup.lines];
        newLines[updatedLine.index] = updatedLine;
      } else {
        newLines = updatedGroup.lines;
      }
      let totalHT = 0;
      newLines.forEach((line) => {
        totalHT = totalHT + parseFloat(line.quantity * line.unitPrice);
      });
      setTotalHT(totalHT);
      props.group.totalHT = totalHT;
      let newgroup;
      if (updatedLine !== undefined) {
        newgroup = {
          ...newGroup,
          totalHT: totalHT,
          index: props.index,
          quoteId: props.quoteId,
          lines: newLines,
        };
      } else {
        newgroup = {
          ...updatedGroup,
          totalHT: totalHT,
          index: props.index,
          quoteId: props.quoteId,
          lines: newLines,
        };
      }
      props.group.lines = newLines;
      props.updateQuote(newgroup, undefined, undefined, undefined);
    },
    [props]
  );

  useEffect(() => {
    updateGroup(undefined, props.group);
    //eslint-disable-next-line
  }, []);

  const onChange = (e) => {
    let value = e.target.value;
    if (e.target.name === "designation") {
      var newGrp = { ...props.group, title: value };
    }
    updateGroup(undefined, newGrp);
  };

  const addLine = () => {
    let newGroup = { ...props.group };
    let id = generateId();
    newGroup.lines.push({
      id: id,
      name: "",
      unit: "",
      quantity: "1.00",
      unitPrice: "0.00",
      totalHT: "0.00",
      vatId: 8,
      unitId: 1,
      numero: newGroup.lines.length + 1,
    });
    props.updateQuote(newGroup);
  };

  const generateId = () => {
    let prefixIdLine = "New";
    let newIdLig = 0;

    Array.from(props.group.lines).forEach((line) => {
      while (line.id === prefixIdLine + newIdLig) {
        newIdLig += 1;
      }
    });

    let newIdHtml = prefixIdLine + newIdLig;
    return newIdHtml;
  };

  const handleMoveLine = (index, direction) => {
    index = parseInt(index);
    let newGroup = { ...props.group };
    let lines = newGroup.lines;
    if (direction === "up" && index > 1) {
      const lineTargetIndex = lines.findIndex(
        (line) => parseInt(line.numero) === index
      );
      const lineBeforeIndex = lines.findIndex(
        (line) => parseInt(line.numero) === index - 1
      );
      if (lineTargetIndex !== -1 && lineBeforeIndex !== -1) {
        const newLines = [...lines];
        newLines[lineBeforeIndex].numero = index;
        newLines[lineTargetIndex].numero = index - 1;
      }
    } else if (direction === "down" && index < lines.length) {
      const lineTargetIndex = lines.findIndex(
        (line) => parseInt(line.numero) === index
      );
      const nextIndex = index + 1;
      const lineAfterIndex = lines.findIndex(
        (line) => parseInt(line.numero) === nextIndex
      );
      if (lineTargetIndex !== -1 && lineAfterIndex !== -1) {
        const newLines = [...lines];
        newLines[lineAfterIndex].numero = index;
        newLines[lineTargetIndex].numero = nextIndex;
      }
    }
    updateGroup(undefined, newGroup);
  };

  const onLineChange = (lineId, field, newValue) => {
    const updatedLines = props.group.lines.map((line) =>
      line.id === lineId ? { ...line, [field]: newValue } : line
    );
    let newGroup = { ...props.group };
    newGroup.lines = updatedLines;
    updateGroup(undefined, newGroup);
  };

  const validPopContent = {
    title: "Suppression",
    desc: `Êtes-vous sûr de vouloir supprimer le groupe "${props.group.title}" ?`,
  };

  const removeLine = (lineId) => {
    let newGroup = { ...props.group };
    let newLines = newGroup.lines.filter((line) => line.id !== lineId);
    newLines = newLines.map((line, index) => {
      line.numero = index + 1;
      return line;
    });
    newGroup.lines = newLines;
    updateGroup(undefined, newGroup);
  };

  return (
    <div className="invoiceGroup">
      <div className="invoiceGroup__groupContainer">
        <div className="invoiceGroup__groupContainer_groupHeader">
          <input
            name="designation"
            onChange={onChange}
            className="invoiceGroup__groupContainer_groupHeader_input"
            type="text"
            placeholder="Nom du groupe d'articles"
            value={designation || ""}
          />
          <div className="invoiceGroup__groupContainer_groupHeader_total">
            <p>
              Montant HT : <span>{(totalHT || 0).toFixed(2)}</span> €
            </p>
            <p className="invoiceGroup__groupContainer_groupHeader_total_discount">
              Montant après remise : <span>{(totalHT || 0).toFixed(2)}</span>
              &nbsp;€
            </p>
          </div>
          <div className="invoiceGroup__groupContainer_groupHeader">
            <div className="invoiceGroup__groupContainer_groupHeader_arrowGroup">
              <p
                onClick={() => {
                  props.changeOrderGroup(props.numero, "up");
                }}
              >
                <FontAwesomeIcon
                  className="invoiceGroup__groupContainer_groupHeader_arrowGroup_arrow"
                  icon={faChevronUp}
                />
              </p>
              <p
                onClick={() => {
                  props.changeOrderGroup(props.numero, "down");
                }}
              >
                <FontAwesomeIcon
                  className="invoiceGroup__groupContainer_groupHeader_arrowGroup_arrow"
                  icon={faChevronDown}
                />
              </p>
              <DeletButton
                deleteItemByNotId={props.removeGroup}
                url={"c-delete-quote-group"}
                refresh={props.refresh}
                setRefresh={props.setRefresh}
                id={props.group.id}
                className={
                  "invoiceGroup__groupContainer_groupHeader_arrowGroup_deleteButton"
                }
                validPopContent={validPopContent}
                color={"red"}
              />
            </div>
          </div>
        </div>
        {props?.group.lines
          ?.sort((a, b) => a.numero - b.numero)
          .map((line, i) => (
            <QuoteLine
              key={i}
              index={i}
              line={line}
              id={line.id}
              grpId={props.id}
              grpIndex={props.index}
              allTva={props.allTva}
              listTva={props.listTva}
              listUnit={props.listUnit}
              group={props.group}
              updateGroup={updateGroup}
              changeOrderLine={handleMoveLine}
              onLineChange={onLineChange}
              openCatalogues={props.openCatalogues}
              refresh={props.refresh}
              setRefresh={props.setRefresh}
              removeLine={removeLine}
            />
          ))}
        <button
          onClick={addLine}
          className="invoiceGroup__groupContainer_addLineButton"
        >
          <FontAwesomeIcon
            className="invoiceGroup__groupContainer_addLineButton_icon"
            icon={faPlus}
          />
        </button>
      </div>
    </div>
  );
}

export default QuoteGroup;
