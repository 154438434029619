import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faCircleCheck,
  faClock,
  faEllipsisV,
  faEuro,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { stateTransition } from "../../utils/constants";
import { useEffect, useRef, useState } from "react";
import CardOptionPop from "./popComponents/cardOptionsPop";

export default function Card({
  tableName,
  setListRefresh,
  deleteEndPoint,
  element,
  otherButtonList,
  stateButtonsList,
  handleOpenValidItem,
  roleVerif,
  elementTitle,
}) {
  //state
  const navigate = useNavigate({ element });
  const [stateTranslate, setStateTranslate] = useState();
  const [showPop, setShowPop] = useState(false);

  //ref
  const popupRef = useRef(null);
  const buttonRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowPop(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef, buttonRef]);

  useEffect(() => {
    if (element.state) {
      const translate = stateTransition.filter((e) => e.name === element.state);

      setStateTranslate(translate[0]);
    }
  }, [element.state]);

  const open = () => {
    localStorage.removeItem("parentPath");
    if (!element.valid || parseInt(element.valid) === 0) {
      navigate(`${element.directionPath}${element.id}`);
    } else {
      handleOpenValidItem(element.id);
    }
  };

  return (
    <>
      <div className="cards" key={element.id} id={element.id}>
        <div className="cards__contentContainer" onClick={open}>
          <div className="cards__contentContainer_titleContainer">
            <p className="cards__contentContainer_titleContainer_title">
              {elementTitle ? (
                <span className="cards__contentContainer_titleContainer_title_light">
                  {elementTitle}{" "}
                </span>
              ) : (
                ""
              )}
              <span className="cards__contentContainer_titleContainer_title_span">
                {element.ref} {element.subject ? <> &bull; </> : ""}{" "}
                {element.subject}
              </span>
              <span className="cards__contentContainer_titleContainer_title_initials">
                {element.initials ? <> &bull; </> : ""}
                {element.initials}
              </span>
            </p>
            {element.state && (
              <div className="cards__contentContainer_titleContainer_stateContainer">
                <p
                  className="cards__contentContainer_titleContainer_stateContainer_state"
                  id={element.state}
                >
                  {element.valid.toString() === "1" &&
                  (element.state === "valid" || element.state === "validated")
                    ? "Finalisé"
                    : stateTranslate?.translate}
                </p>
                <FontAwesomeIcon
                  style={{ fill: "red", fontSize: "1.3rem" }}
                  className={`cards__contentContainer_titleContainer_stateContainer_icon state${stateTranslate?.color}`}
                  icon={faCircleCheck}
                />
              </div>
            )}
          </div>
          {element.origin && (
            <p className="cards__contentContainer_depositOrigin">
              {element.origin}
            </p>
          )}
          <p className="cards__contentContainer_name">
            {element.lastName} {element.firstName} {element?.society ? "-" : ""}{" "}
            {element?.society}
          </p>

          <div className="cards__contentContainer_content">
            {element.depositRate ? (
              <p className="cards__contentContainer_content_price cards__contentContainer_content_ht">
                {element.depositRate}%
              </p>
            ) : (
              <>
                <FontAwesomeIcon
                  className="cards__contentContainer_content_icon"
                  icon={faEuro}
                />
                <p className="cards__contentContainer_content_price cards__contentContainer_content_ht">
                  {parseFloat(element.totalHt || 0).toFixed(2)} € HT
                </p>
              </>
            )}

            <span className="cards__contentContainer_content_bar">|</span>
            <p className="cards__contentContainer_content_price">
              {parseFloat(element.totalTtc || 0).toFixed(2)} € TTC
            </p>
            <span className="cards__contentContainer_content_bar">|</span>
            <FontAwesomeIcon
              className="cards__contentContainer_content_icon"
              icon={faClock}
            />
            <p className="cards__contentContainer_content_date">
              {element.date?.split("-").reverse().join("/")}
            </p>
          </div>
        </div>
        <div className={`cards__options ${showPop ? "optionsActive" : ""}`}>
          <CardOptionPop
            roleVerif={roleVerif}
            tableName={tableName}
            setShowPop={setShowPop}
            setListRefresh={setListRefresh}
            deleteEndPoint={deleteEndPoint}
            id={element.id}
            otherButtonList={otherButtonList}
            stateButtonsList={stateButtonsList}
            popupRef={popupRef}
            showPop={showPop}
          />
          <FontAwesomeIcon
            ref={buttonRef}
            className={`cards__options_optionsButton ${
              showPop ? "optionButtonActive" : ""
            }`}
            onClick={(event) => {
              event.stopPropagation();
              setShowPop(!showPop);
            }}
            icon={showPop ? faXmark : faEllipsisV}
          />
        </div>
      </div>
    </>
  );
}
