import { AppContext } from "./../../context/appContext";
import { useContext, useRef } from "react";
import { SendMailApiContext } from "./../../context/apiCalls/sendMailApiCalls";

export default function MailPop({ endpoint, id }) {
  const appContext = useContext(AppContext);

  const recipientRef = useRef("");
  const subjectRef = useRef("");
  const bodyRef = useRef("");
  const fileNameRef = useRef("");

  const SendMailApiCalls = useContext(SendMailApiContext);

  const handleSubmit = async (e) => {
    e?.preventDefault();

    const values = {
      id: id,
      destinataire: recipientRef.current.value,
      subject: subjectRef.current.value,
      message: bodyRef.current.value,
      filename: fileNameRef.current.value,
    };

    console.log("id", id);

    const response = SendMailApiCalls.sendMail(endpoint, values);
    try {
      console.log("reponse back", await response);
    } catch (error) {
      console.log("error", error);
    }
    appContext.setShowMailPop(false);
  };

  const showMailPop = (id) => {
    appContext.setShowMailPop(!appContext.showMailPop);
    appContext.setMailId(id);
  };

  return (
    <>
      {appContext.showMailPop && (
        <form className="mailpop" onSubmit={handleSubmit}>
          <div className="mailpopHeader">
            <h3>Envoyer un mail</h3>
            {/* <a href="#" onClick={appContext.setShowMailPop(false)}>X</a> */}
            <button onClick={showMailPop}>X</button>
          </div>
          <div className="mailpopContainer">
            <label>Mail : </label>
            <input ref={recipientRef} type="text" placeholder="Destinataire" />

            <label>Objet : </label>
            <input ref={subjectRef} type="text" placeholder="Sujet" />

            <label>Message : </label>
            <textarea ref={bodyRef} placeholder="Message ..." />

            <label>Nom du fichier : </label>
            <input ref={fileNameRef} type="text" placeholder="Nom du fichier" />

            <button type="submit">Envoyer</button>
          </div>
        </form>
      )}
    </>
  );
}
