import OptionsButtonPop from "../buttonComponent/optionsButtonPop";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { DeleteApiContext } from "../../../context/apiCalls/deleteApiCalls";
import { PostApiContext } from "../../../context/apiCalls/postApiCalls";
import ValidPop from "./validPop";

export default function CardOptionPop({
  deleteEndPoint,
  id,
  showPop,
  setShowPop,
  popupRef,
  stateButtonsList,
  otherButtonList,
  setListRefresh,
  tableName,
  roleVerif,
}) {
  const deleteApiCalls = useContext(DeleteApiContext);
  const postApiCalls = useContext(PostApiContext);

  const navigate = useNavigate();
  const [showValidPop, setShowValidPop] = useState(false);

  const deleteItem = () => {
    deleteApiCalls.deleteItem(deleteEndPoint, id).then(() => {
      setShowValidPop(false);
      setShowPop(false);
      setListRefresh(true);
    });
  };

  const handleChangeState = (e) => {
    const values = {
      id: id,
      state: e.target.name,
      tableName: tableName,
    };

    console.log(values);

    postApiCalls.stateChange(values).then(() => {
      setListRefresh(true);
    });
  };

  const shouldInsertHr = (index, length) => {
    return (index + 1) % 3 === 0 || index === length - 1;
  };

  const buttonsObject = [
    {
      text: "Retour",
      className: "backButton",
      action: () => setShowValidPop(false),
    },
    { text: "Continuer", className: "continueButton", action: deleteItem },
  ];

  return (
    <>
      {showValidPop && (
        <ValidPop
          showValidPop={showValidPop}
          setShowValidPop={setShowValidPop}
          title={"Suppression"}
          desc={"Voulez-vous vraiment supprimer cet élément ?"}
          buttonsObject={buttonsObject}
          bgcAction={() => setShowValidPop(false)}
        />
      )}

      <div
        ref={popupRef}
        id={id}
        className={`cards__options_content ${
          showPop ? "optionsContentActive" : ""
        }`}
      >
        <OptionsButtonPop
          action={() => navigate(tableName + `/${id}`)}
          icon={faPenToSquare}
          className={"editButton"}
          title={"Modifier"}
        />
        <hr className="cards__options_content_line" />
        {stateButtonsList &&
          stateButtonsList.map((button, index) => {
            return (
              <div key={index}>
                <OptionsButtonPop
                  name={button.name}
                  title={button.title}
                  className={button.className}
                  icon={button.icon}
                  action={(e) => handleChangeState(e)}
                  color={button.color}
                />
                {shouldInsertHr(index, otherButtonList?.length) && (
                  <hr className="cards__options_content_line" />
                )}
              </div>
            );
          })}
        {otherButtonList &&
          otherButtonList.length > 0 &&
          otherButtonList?.map((button, index) => {
            return (
              <div key={index}>
                <OptionsButtonPop
                  title={button.title}
                  className={button.className}
                  icon={button.icon}
                  action={button.action}
                />
                {shouldInsertHr(index, otherButtonList.length) && (
                  <hr className="cards__options_content_line" />
                )}
              </div>
            );
          })}
        {/*<hr/>*/}
        {roleVerif && (
          <OptionsButtonPop
            action={() => setShowValidPop(true)}
            icon={faTrashCan}
            className={"deleteButton"}
            title={"Supprimer"}
          />
        )}
      </div>
    </>
  );
}
