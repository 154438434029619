import { createContext, useEffect, useState } from "react";

export const PopupContext = createContext(null);

export default function PopupProvider(props) {
  const [popups, setPopups] = useState({
    showContactPopup: false,
    showDepositPopup: false,
  });

  // Gestion de l'ajout et retrait de la classe pour désactiver le scroll
  useEffect(() => {
    const mainElement = document.getElementsByClassName("main")[0]; // Sélectionne le conteneur principal
    if (mainElement === undefined) return;

    const anyPopupActive = Object.values(popups).some((value) => value);
    if (anyPopupActive) {
      mainElement.style.position = "fixed";
    } else {
      mainElement.style.position = "";
    }

    // Nettoyage de l'effet quand le composant est démonté
    return () => {
      mainElement.style.position = "";
    };
  }, [popups]);

  const setPopupVisibility = (popupName, isVisible) => {
    setPopups((prev) => ({
      ...prev,
      [popupName]: isVisible,
    }));
  };

  return (
    <PopupContext.Provider
      value={{
        popups,
        setPopupVisibility,
      }}
    >
      {props.children}
    </PopupContext.Provider>
  );
}
