import { PieChart } from "@mui/x-charts/PieChart";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";

export default function Pie(props) {
  //useState
  const [startPeriodYear, setStartPeriodYear] = useState();
  const [finishPeriodYear, setFinishPeriodYear] = useState();
  const [nbValidAndInvalid, setNbValidAndInvalid] = useState([]);

  //transforme l'annee (startPeriodeYear YYYY-MM-DD) en anne DD-MM-YYYY
  const DatePeriode = new Date(startPeriodYear);
  const dayDP = DatePeriode.getDate().toString().padStart(2, "0");
  const monthDP = (DatePeriode.getMonth() + 1).toString().padStart(2, "0");
  const yearDP = DatePeriode.getFullYear();
  const startYear = `${dayDP}/${monthDP}/${yearDP}`;

  const DateFinPeriode = new Date(finishPeriodYear);
  const dayFP = DateFinPeriode.getDate().toString().padStart(2, "0");
  const monthFP = (DateFinPeriode.getMonth() + 1).toString().padStart(2, "0");
  const yearFP = DateFinPeriode.getFullYear();
  const endYear = `${dayFP}/${monthFP}/${yearFP}`;

  useEffect(() => {
    setStartPeriodYear(props.startPeriodYear);
    setFinishPeriodYear(props.finishPeriodYear);
    setNbValidAndInvalid(props.nbValidAndInvalid);
  }, [props.startPeriodYear, props.finishPeriodYear, props.nbValidAndInvalid]);

  const pieParams = { height: 200, margin: { right: 5 } };

  const invoiceData = [
    {
      id: 0,
      value: nbValidAndInvalid?.totalValidInvoices || 0,
      color: "#FDAB0D",
    },
    {
      id: 1,
      value: nbValidAndInvalid?.totalInvalidInvoices || 0,
      color: "#C4C4C4",
    },
  ];

  const quoteData = [
    {
      id: 0,
      value: nbValidAndInvalid?.totalValidQuotes || 0,
      color: "#FDAB0D",
    },
    {
      id: 1,
      value: nbValidAndInvalid?.totalInvalidQuotes || 0,
      color: "#C4C4C4",
    },
  ];

  const orderData = [
    {
      id: 0,
      value: nbValidAndInvalid?.totalValidDeposits || 0,
      color: "#FDAB0D",
    },
    {
      id: 1,
      value: nbValidAndInvalid?.totalInvalidDeposits || 0,
      color: "#C4C4C4",
    },
  ];

  const cashed = [
    {
      id: 0,
      value: nbValidAndInvalid?.totalValidInvoices || 0,
      color: "#FDAB0D",
    },
    {
      id: 1,
      value: nbValidAndInvalid?.totalInvalidInvoices || 0,
      color: "#C4C4C4",
    },
  ];

  return (
    <>
      <div className="graphContent">
        <div className="graphContent__grid">
          <div className="graphContent__grid_left leftGraph graph">
            <p className="graphContent__grid_left_desc">
              Informations générales sur la période du {startYear} au {endYear}
            </p>
            <div className="graphContent__grid_left_pie">
              <div className="graphContent__grid_left_pie_label leftGraphLabel">
                <div className="pieLabel">
                  <div className="labelColor1 labelColor"></div>
                  <p>Encaissé</p>
                </div>
                <div className="pieLabel">
                  <div className="labelColor2 labelColor"></div>
                  <p>Non encaissé</p>
                </div>
              </div>
              <PieChart
                series={[
                  {
                    data: cashed,
                    outerRadius: 100,
                  },
                ]}
                width={400}
                height={200}
              />
            </div>
          </div>
          <div className="graphContent__grid_right">
            <p className="graphContent__grid_right_desc">
              Informations générales sur la période du {startYear} au {endYear}
            </p>
            <div className="graphContent__grid_right_pie">
              <div className="rightLabel">
                <div className="pieLabel">
                  <div className="labelColor1 labelColor"></div>
                  <p>Validé(e)s</p>
                </div>
                <div className="pieLabel">
                  <div className="labelColor2 labelColor"></div>
                  <p>Non validé(e)s</p>
                </div>
              </div>
              <Stack direction="row" width="100%" textAlign="center">
                <Box flexGrow={1}>
                  <Typography className="pieLabelTitle">Factures</Typography>
                  <PieChart
                    series={[
                      {
                        data: invoiceData,
                        innerRadius: 40,
                        outerRadius: 100,
                      },
                    ]}
                    {...pieParams}
                  />
                </Box>
              </Stack>
              <Stack
                direction="column"
                width="100%"
                textAlign="center"
                spacing={2}
              >
                <Box className="firstPieBox" flexGrow={1}>
                  <Typography className="pieLabelTitle" id={"quotePieLabel"}>
                    Devis
                  </Typography>
                  <PieChart
                    series={[
                      {
                        data: quoteData,
                        outerRadius: 100,
                      },
                    ]}
                    {...pieParams}
                  />
                </Box>
                <Box className="secondPieBox" flexGrow={1}>
                  <Typography className="pieLabelTitle" id={"depositPieLabel"}>
                    Acomptes
                  </Typography>
                  <PieChart
                    series={[
                      {
                        data: orderData,
                        innerRadius: 20,
                        outerRadius: 100,
                      },
                    ]}
                    {...pieParams}
                  />
                </Box>
              </Stack>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
