import React from 'react';
import Footer from "../../components/Authentification/footer";
import BasicInput from "../../components/addOns/inputComponents/basicInput";
import MainButton from "../../components/addOns/buttonComponent/MainButton";
import { useRef} from "react";
import {useNavigate} from "react-router-dom";
import Header from "../../components/Authentification/header";
import leftArrow from "../../assets/images/picto/left-arrow.svg";


export default function ForgotPassword() {

	const handleSubmit = async (e) => {
		e.preventDefault();	
	}

	const emailRef = useRef();
	const navigate = useNavigate();

	return (
		<>
			<Header navigate={navigate}/>
            <div className="loginFormPosition">
			<section className="loginForm">
				<h1 className='loginForm__title'><span className="loginForm__title_span">Mot de passe oublié</span>
				<hr className="loginForm__line"/>
				</h1>
				<div className="loginForm__content">
					<div className="loginForm__content_textContainer">
						<h2 className='loginForm__content_textContainer_title'>Mot de passe oublié ?</h2>
						<p className="loginForm__content_textContainer_text">Entrez votre email et recevez un lien pour réinitialiser votre mot de passe</p>
						<button className='loginForm__content_textContainer_button' onClick={() => navigate('/')}>
							<img src={leftArrow} alt='←' />
							Revenir</button>
					</div>
					<form className="loginForm__content_form" onSubmit={handleSubmit}>
						<BasicInput autoComplete={"email"} labelName={"Email"} id={"loginId"}
									className={"loginForm__content_form_inputContainer first-label"} refs={emailRef}
									placeholder={""}/>
						<MainButton className='loginForm__content_form_button' buttonText={"Envoyer un lien"} buttonAction={handleSubmit}/>
					</form>
				</div>
				<Footer navigate={navigate}/>
			</section>
			</div>
			<p className='copyright'>2024 - Capbox • Tous droits réservés</p>
		</>
	)
}