import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sauvegarder from "../../../assets/images/icons/Picto/Sauvegarder_white.svg";

export default function MainButton({
  buttonIcon,
  buttonText,
  buttonAction,
  className,
}) {
  return (
    <>
      <button
        onClick={buttonAction}
        className={`toolbar__container_button button ${className}`}
      >
        {buttonIcon && buttonIcon === "sauvegarder" ? (
          <img
            src={sauvegarder}
            alt="save"
            className="toolbar__container_button_icon"
          />
        ) : (
          buttonIcon && (
            <FontAwesomeIcon
              icon={buttonIcon}
              className="toolbar__container_button_icon"
            />
          )
        )}
        {buttonText}
      </button>
    </>
  );
}
