import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../context/appContext';
import {faFileCirclePlus, faPenToSquare} from "@fortawesome/free-solid-svg-icons";

export default function Prospect() {

    const appContext = useContext(AppContext);

    let { id } = useParams();
    const [prospect, setProspect] = useState([]);

    useEffect(() => {
        appContext.setIdContact(id);
    }, [id, appContext]);

    useEffect(() => {

        const limit = 5;

        axios.get(`${process.env.REACT_APP_CONTACT_PROSPECT_LIST_GET}${id}&limit=${limit}`)
            .then(response => {
                setProspect(response.data.listProspectings);
                appContext.setReload(false)
                appContext.setProspectValue(response.data.listProspectings);
            });
    }, [appContext.reload, appContext, id]);

    const showProspectListPop = () => {
        window.monEcouteurScroll = function (e) {
            e.preventDefault();
            window.scrollTo(0, 0);
        };
        window.addEventListener('scroll', window.monEcouteurScroll);

        appContext.setBlur(true);
        appContext.setShowProspectListPopup(!appContext.showProspectListPopup);
    };

    const showCreateProspectPopup = () => {
        appContext.setShowCreateProspectPopup(!appContext.showCreateProspectPopup)
        appContext.setBlur(true);
    }

    const editProspect = (e) => {
        appContext.setIdprospect(e);
        appContext.setShowEditProspectPopup(!appContext.showEditProspectPopup);
        appContext.setBlur(true);
        appContext.setShowProspectListPopup(false);
    }

    return (

        <>
            <div className='table'>
                <div className='titleLine'>
                    <h3>Historique des prospections</h3>
                    <p className='create-prospect' onClick={showCreateProspectPopup}><FontAwesomeIcon className='tableIcon' icon={faFileCirclePlus} /> Ajouter une prospection</p>
                </div>
                <div className='borderForm'>
                    <table>
                        <thead>
                            <tr>
                                <th className='th1'>Appel</th>
                                <th className='th2'>Rendez-vous</th>
                                <th className='th3'>Relance</th>
                                <th className='th4'>Commentaire</th>
                                {prospect &&
                                    prospect.length > 0 &&
                                    <th className='th5'>Modifier</th>
                                }
                            </tr>
                        </thead>
                        <tbody className='tbody'>

                            {
                                prospect && prospect.length > 0 ? (
                                    prospect.map((element, index) => {
                                        return (
                                            <tr className='tr' key={index}>
                                                <td className='td1'>{element?.call ? element.call : "vide"}</td>
                                                <td className='td2'>{element?.meeting ? element.meeting : "vide"}</td>
                                                <td className='td3'>{element?.dunning ? element.dunning : "vide"}</td>
                                                <td className='td4'>{element?.comment ? element.comment : "comment vide"}</td>
                                                <td className='tdEdit'>
                                                    <FontAwesomeIcon onClick={() => { editProspect(element.id) }} className='editIcon' icon={faPenToSquare} />
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr className='tr'>

                                    </tr>
                                )
                            }

                        </tbody>
                    </table>
                </div>
                <div className='seeMore'>
                    {
                        prospect && prospect?.length > 0 ?
                            <p className='seeMoreButton' onClick={showProspectListPop}>Voir plus</p>
                            :
                            <p className='seeMoreButton'>Pas de prospection</p>
                    }
                </div>
            </div>

        </>

    )
}