import Total from "../invoices/invoice/recap/total";

export default function MarginPrice(data) {
    return (
        <aside className="priceRecap">
            <Total title="Coût entreprise HT attendu" price={data.data.expectedCost}/>
            <Total title="Coût entreprise HT réel" price={data.data.realCost}/>
            <div className="marginPrice__tva">
                <div>
                    <p>MO prévue HT</p>
                    <p>{data.data.plannedManpower?.toFixed(2)} €</p>
                </div>
                <div>
                    <p>MO réelle HT</p>
                    <p>{data.data.realManpower?.toFixed(2)} €</p>
                </div>
                <div>
                    <p>Coût total HT (Hors MO)</p>
                    <p>{data.data.totalCostHT?.toFixed(2)} €</p>
                </div>
            </div>
            <Total title="Montant total HT facture" price={data.data.totalHT}/>
            <Total title="Total marge brute" price={data.data.realTotalMargin}/>
            <Total title="% Marge réelle" price={`${data.data.realPercentMargin?.toFixed(2)}`}/>
        </aside>
        
    );
}