export default function BasicSelect({
  options,
  name,
  selectValue,
  defaultOptionName,
  action,
  selectClassName,
  optionClassName,
  hover,
}) {
  return (
    <>
      <select
        className={`basicSelect ${selectClassName}`}
        onChange={action}
        value={selectValue}
        name={name}
      >
        {defaultOptionName && (
          <option className={`basicSelect__option ${optionClassName}`} value="">
            {defaultOptionName}
          </option>
        )}
        {options &&
          options.length > 0 &&
          options.map((option, index) => {
            return (
              <option
                className={`basicSelect__option ${optionClassName}`}
                value={option.value ?? option.id}
                key={index}
              >
                {option.label ??
                  (option.first_name ?? "") + " " + (option.last_name ?? "")}
              </option>
            );
          })}
      </select>
    </>
  );
}
