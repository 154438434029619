import Footer from "./footer";
import {useContext, useRef, useState} from "react";
import {PostApiContext} from "../../context/apiCalls/postApiCalls";
import BasicInput from "../addOns/inputComponents/basicInput";
import MainButton from "../addOns/buttonComponent/MainButton";
import BasicPassInput from "../addOns/inputComponents/basicPassInput";
import useAuth from "../../hooks/useAuth";

export default function LoginForm({navigate}) {

    const postApiCall = useContext(PostApiContext)

    const loginIdRef = useRef();
    const passwordRef = useRef();

    const [errorMessage, setErrorMessage] = useState("");
    const token = localStorage.getItem("acces_token")

    const handleSubmit = async (e) => {
        e.preventDefault();

        const values = {
            loginId: loginIdRef.current.value,
            password: passwordRef.current.value
        };
        if (!token) {
            await postApiCall.login(values).then(
                (response) => {
                    // setMessage(response?.message);
                    setErrorMessage(response?.error);
                    if (response?.token) {
                        // localStorage.setItem("acces_token", response.token);
                        login(response.token);
                        navigate("/");
                    }
                }
            )
        } else {
            console.log("token already exist");
            navigate("/");
        }
    }

    const {login} = useAuth();


    return (
        <>
            <section className="loginForm">
                <h1 className='loginForm__title'>Bonjour 🙂<span className="loginForm__title_span">, connectez-vous à votre espace privé</span>
                <hr className="loginForm__line"/>
                </h1>
                <div className="loginForm__content">
                    <div className="loginForm__content_textContainer">
                        <h2 className='loginForm__content_textContainer_title'>Bienvenue sur CAP BOX</h2>
                        <h3 className="loginForm__content_textContainer_subtitle">Solution de gestion pour les TPE,
                            associations, professions&nbsp;libérales.</h3>
                        <p className="loginForm__content_textContainer_text">Véritable copilote de votre activité, <span
                            className="loginForm__content_textContainer_text_span">CAP BOX</span> intégre toutes les
                            fonctionnalités
                            nécessaires au
                            fonctionnement de votre entreprise (tableau de bord, gestion des marges, base de données,
                            comparateur de prix).</p>
                    </div>
                    <form className="loginForm__content_form" onSubmit={handleSubmit}>
                        <BasicInput autoComplete={"email"} labelName={"Identifiant"} id={"loginId"}
                                    className={"loginForm__content_form_inputContainer first-label"} refs={loginIdRef}
                                    placeholder={""}/>
                        <BasicPassInput labelName={"Mot de passe"} id={"password"} refs={passwordRef}
                                        className={"loginForm__content_form_inputContainer"}
                                        placeholder={""}/>
                        <p className="loginForm__content_form_errorMessage">{errorMessage}</p>
                        <MainButton className='loginForm__content_form_button' buttonText={"Se connecter"} buttonAction={handleSubmit}/>
                        <p className='loginForm__content_form_link' onClick={() => navigate("/forgotPassword")}>Mot de passe oublié ?</p>
                        {/* <label className="loginForm__content_form_checkbox-container">Se souvenir de moi
                            <input type="checkbox" id="rememberMe" name="rememberMe" value="Se souvenir de moi"/>
                            <span htmlFor="rememberMe" className="checkmark"></span>
                        </label>
                        <p className='loginForm__content_form_left-link'>Pas encore de compte ?</p>
                        <MainButton className='loginForm__content_form_button' buttonText={"Créer un compte"} buttonAction={() => navigate('/register')}/> */}
                    </form>
                </div>
                <Footer navigate={navigate}/>
            </section>
        </>
    )
}