import PriceRecap from "./priceReacp";
import LinkedDepositList from "../../../deposit/LinkedDepositList";
import MarginCreator from "../../../../addOns/MarginCreator";
import { useCallback } from "react";

export default function RecapContainer({
  linkDeposit,
  handleSubmitInvoice,
  invoiceId,
  totalTtc,
  totalHt,
  discount,
  handleDiscountChange,
  handleDiscountBlur,
  totalInvoice,
  vatList,
  deleteDeposit,
  createDeposit,
  discountPrice,
  setRefresh,
  margin,
  setMargin,
  invoice,
}) {
  const onDepositLinkClick = (e, path) => {
    handleSubmitInvoice(e, path);
  };

  const updateMargin = useCallback(
    (field, value) => {
      setMargin((margin) => {
        return { ...margin, [field]: value };
      });
    },
    [setMargin]
  );

  return (
    <section className="reacpContainer">
      <PriceRecap
        totalTtc={totalTtc}
        totalHt={totalHt}
        discount={discount}
        discountPrice={discountPrice || 0.0}
        handleDiscountChange={handleDiscountChange}
        handleDiscountBlur={handleDiscountBlur}
        totalInvoice={totalInvoice}
        vatList={vatList}
      />
      {linkDeposit && (
        <LinkedDepositList
          linkDeposit={linkDeposit}
          onSubmit={onDepositLinkClick}
          deleteDeposit={deleteDeposit}
          createDeposit={createDeposit}
          parentPath={`/invoicesList/invoice/${invoiceId}`}
          setRefresh={setRefresh}
        />
      )}
      {margin && (
        <MarginCreator
          margin={margin}
          object={invoice}
          onValidObject={handleSubmitInvoice}
          updateMargin={updateMargin}
          parent={"invoice"}
        />
      )}
    </section>
  );
}
