import { useContext, useEffect, useRef, useState, useMemo } from "react";
import ArticleCard from "../../components/customer/catalogues/ArticleCard";
import CreatePersonalItemPop from "../../components/customer/catalogues/cataloguePops/createPersonalItemPop";
import StateFilter from "../../components/addOns/filterComponent/stateFilter";
import SearchComponent from "../../components/addOns/filterComponent/searchComponent";
import MainHeader from "../../components/addOns/headers/MainHeader";
import {
  faFileMedical,
  faPaperPlane,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/addOns/loader";
import CatalogCRUDPop from "../../components/customer/catalogues/cataloguePops/CatalogCRUDPop";
import { PostApiContext } from "../../context/apiCalls/postApiCalls";
import { AppContext } from "../../context/appContext";
import Pagination from "../../components/addOns/Pagination";

export default function CataloguesList({ isPopup, onSelectArticle }) {
  const [catalogList, setCatalogList] = useState([]);
  const [showArticlePopup, setShowArticlePopup] = useState(false);
  const [articlePopState, setArticlePopState] = useState("");
  const [subfamilyList, setSubfamilyList] = useState([]);
  const [familyList, setFamilyList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState([]);
  const [switchedItem, setSwitchedItem] = useState("all");
  const [itemList, setItemList] = useState([]);
  const [itemCopy, setItemCopy] = useState({});
  const [unitList, setUnitList] = useState([]);
  const [personalCatalogList, setPersonalCatalogList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCatalogCRUD, setShowCatalogCRUD] = useState(false);
  const [catalogCRUDPopAction, setCatalogCRUDPopAction] = useState("create");
  const [refresh, setRefresh] = useState(false);
  const [personal, setPersonal] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [nbPages, setNbPages] = useState(1);
  const [formState, setFormState] = useState({
    catalog: "",
    family: "",
    subFamily: "",
    brand: "",
    supplier: "",
    manufacturerRef: "",
    distributerRef: "",
    designation: "",
  });

  const manufacturerRef = useRef();
  const distributerRef = useRef();
  const designationRef = useRef();

  const postApiContext = useContext(PostApiContext);
  const appContext = useContext(AppContext);

  const memoizedValues = useMemo(
    () => ({
      catalog: formState.catalog,
      family: formState.family,
      subFamily: formState.subFamily,
      brand: formState.brand,
      provider: formState.supplier,
      refMaker: manufacturerRef.current?.value,
      refDistributor: distributerRef.current?.value,
      designation: designationRef.current?.value,
      personal: personal,
    }),
    [formState, manufacturerRef, distributerRef, designationRef, personal]
  );

  useEffect(() => {
    setPersonal(
      catalogList?.some(
        (catalog) =>
          formState.catalog === catalog.id && Number(catalog.admin_id) > 100
      )
    );
  }, [catalogList, formState.catalog]);

  useEffect(() => {
    setLoading(true);
    // console.log(
    //   "currentPage",
    //   currentPage,
    //   "memoizedValues",
    //   memoizedValues,
    //   "perPage",
    //   perPage
    // );

    postApiContext
      .postCatalogsListFilter(
        memoizedValues,
        switchedItem,
        currentPage,
        perPage
      )
      .then((response) => {
        // console.log(response);
        setCatalogList(response.listCatalogs);
        setSubfamilyList(response.subFamilyList);
        setFamilyList(response.familyList);
        setBrandList(response.brandList);
        setSuppliers(response.supplierList);
        setItemList(response.listItems);
        setUnitList(response.unitList);
        setPersonalCatalogList(response.personalCatalogList);
        setNbPages(response.nbPages);
        setPerPage(response.perPage ?? 50);
        setCurrentPage(response.currentPage);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
        setShowCatalogCRUD(false);
      });
    setRefresh(false);
  }, [
    currentPage,
    memoizedValues,
    perPage,
    postApiContext,
    refresh,
    switchedItem,
  ]);

  const onValidFilter = (e) => {
    e.preventDefault();
    setLoading(true);

    postApiContext
      .postCatalogsListFilter(
        memoizedValues,
        switchedItem,
        currentPage,
        perPage
      )
      .then((response) => {
        setCatalogList(response.listCatalogs);
        setSubfamilyList(response.subFamilyList);
        setFamilyList(response.familyList);
        setBrandList(response.brandList);
        setSuppliers(response.supplierList);
        setItemList(response.listItems);
        setUnitList(response.unitList);
        setPersonalCatalogList(response.personalCatalogList);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
        setShowCatalogCRUD(false);
      });
  };

  useEffect(() => {
    postApiContext
      .postSuppliersList()
      .then((response) => {
        setSuppliers(response.listSuppliers);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [postApiContext, appContext.showContactPopup]);

  const handleItemClick = (e) => {
    if (isPopup && onSelectArticle) {
      onSelectArticle(e);
    } else {
      setSelectedArticle(e);
      setArticlePopState("display");
      setShowArticlePopup(true);
    }
  };

  const handleCreateItem = (e) => {
    setSelectedArticle(null);
    setItemCopy({});
    setArticlePopState("create");
    setShowArticlePopup(true);
  };

  const handleClosePop = () => {
    setShowArticlePopup(false);
  };

  const stateObject = [
    { valid: "all", name: "tous les articles" },
    { valid: "personnal", name: "Articles personnels" },
  ];

  const searchContentObject = {
    ContentName: "Article",
    Content: [
      {
        name: "catalog",
        title: "Catalogue",
        type: "select",
        value: formState.catalog,
        options: catalogList?.map((catalog) => ({
          label: catalog.name,
          id: catalog.id,
        })),
      },
      {
        name: "family",
        title: "Famille",
        type: "select",
        value: formState.family,
        options: familyList?.map((family) => ({
          label: family.family,
        })),
      },
      {
        name: "subFamily",
        title: "Sous-famille",
        type: "select",
        value: formState.subFamily,
        options: subfamilyList?.map((subfamily) => ({
          label: subfamily.subfamily,
        })),
      },
      {
        name: "brand",
        title: "Marque",
        type: "select",
        value: formState.brand,
        options: brandList?.map((brand) => ({
          label: brand.brand,
        })),
      },
      {
        name: "supplier",
        title: "Fournisseur",
        type: "select",
        value: formState.supplier,
        options: suppliers?.map((supplier) => ({
          label: supplier.supplier,
        })),
      },
      {
        name: "manufacturerRef",
        title: "Ref. Fabricant",
        type: "text",
        ref: manufacturerRef,
      },
      {
        name: "distributerRef",
        title: "Ref. Distributeur",
        type: "text",
        ref: distributerRef,
      },
      {
        name: "designation",
        title: "Désignation",
        type: "text",
        ref: designationRef,
      },
    ],
  };

  const headerButtons = [
    {
      title: "Nouvel article",
      className: "",
      icon: faFileMedical,
      action: handleCreateItem,
      showByRoleId: true,
    },
  ];

  let headerDropdownButton = {
    createCatalog: {
      content: "Créer un catalogue",
      handleClick: () => {
        setShowCatalogCRUD(true);
        setCatalogCRUDPopAction("create");
      },
      icon: faPrint,
      redir: false,
    },
    editCatalog: {
      content: "Modifier un catalogue",
      handleClick: () => {
        setShowCatalogCRUD(true);
        setCatalogCRUDPopAction("edit");
      },
      icon: faPaperPlane,
      redir: false,
    },
  };

  const onSearchSelectChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const refreshForm = () => {
    const formulaire = document.getElementById("searchFilterForm");
    formulaire.reset();
    setFormState({
      catalog: "",
      family: "",
      subFamily: "",
      brand: "",
      supplier: "",
      manufacturerRef: "",
      distributerRef: "",
      designation: "",
    });
    setRefresh(true);
  };

  return (
    <>
      {loading && <Loader />}
      {showArticlePopup && (
        <div onClick={handleClosePop} className="bluredDiv"></div>
      )}

      {showArticlePopup && (
        <CreatePersonalItemPop
          setShowArticlePopup={setShowArticlePopup}
          itemCopy={itemCopy}
          setItemCopy={setItemCopy}
          subfamilyList={subfamilyList}
          familyList={familyList}
          brandList={brandList}
          supplierList={suppliers}
          unitList={unitList}
          personalCatalogList={personalCatalogList}
          refresh={refresh}
          setRefresh={setRefresh}
          articlePopState={articlePopState}
          setArticlePopState={setArticlePopState}
          selectedArticle={selectedArticle}
        />
      )}
      {showCatalogCRUD && (
        <CatalogCRUDPop
          catalogs={personalCatalogList}
          action={catalogCRUDPopAction}
          setShowCatalogCRUD={setShowCatalogCRUD}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
      <MainHeader
        headerTitle={"CATALOGUES ET ARTICLES"}
        objectButtons={headerButtons}
        headerDropdownButton={headerDropdownButton}
        dropDownTitle={"Catalogues"}
      />
      <div className="listPage">
        <StateFilter
          setState={setSwitchedItem}
          stateObject={stateObject}
          state={switchedItem}
        />
        <SearchComponent
          contentObject={searchContentObject}
          onSearchSubmit={onValidFilter}
          onSearchSelectChange={onSearchSelectChange}
          refresh={refreshForm}
          title={"Rechercher"}
          defaultShowFilter={true}
        />

        {itemList?.map((item, index) => {
          return (
            <ArticleCard
              index={index}
              handleItemClick={handleItemClick}
              article={item}
              key={index}
            />
          );
        })}
      </div>
      <Pagination
        perPage={perPage}
        setPerPage={setPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        nbPages={nbPages}
      />
    </>
  );
}
