import React from "react";

function TotalAmonts({ totalTTC, totalHT, totalVAT }) {
  
  const formatNumber = (number) => {
    return parseFloat(number).toFixed(2);
  };

  return (
    <div className="total-amonts-wrapper">
      <h2 className="total-amonts-wrapper__title">Total de la page</h2>
      <div className="total-amonts">
        {totalHT !== undefined && totalHT !== null && (
        <div className="total-amonts__item">
          <span className="total-amonts__item_label">Montant total HT</span>
          <span className="total-amonts__item_value">
            {formatNumber(totalHT)} €
          </span>
        </div>)}
        {totalHT !== undefined && totalHT !== null && (
        <div className="total-amonts__separator"></div>)}
        {totalVAT !== undefined && totalVAT !== null && (
        <div className="total-amonts__item">
          <span className="total-amonts__item_label">Montant total TVA</span>
          <span className="total-amonts__item_value">
            {formatNumber(totalVAT)} €
          </span>
        </div>)}
        {totalVAT !== undefined && totalVAT !== null && (
        <div className="total-amonts__separator"></div>)}
        {totalTTC !== undefined && totalTTC !== null && (
        <div className="total-amonts__item">
          <span className="total-amonts__item_label">Montant total TTC</span>
          <span className="total-amonts__item_value">
            {formatNumber(totalTTC)} €
          </span>
        </div>
        )}
      </div>
    </div>
  );
}

export default TotalAmonts;
