import UnderLineSelect from "../selectComponents/underlineSelect";

export default function SortByFilter({ sortOptions, setSort, setListRefresh }) {
  return (
    <div className="sortFilter">
      <UnderLineSelect
        action={(e) => {
          setSort(e.target.value);
          if (setListRefresh) setListRefresh(true);
        }}
        label={"Trier par :"}
        sortOptions={sortOptions}
      />
    </div>
  );
}
