import { createContext } from "react";

export const PostApiContext = createContext(null);

export default function PostApiProvider(props) {
  return (
    <PostApiContext.Provider
      value={{
        postInvoice: API.postInvoice,
        postInvoicesList: API.postInvoicesList,
        stateChange: API.stateChange,
        login: API.login,
        postQuotesList: API.postQuotesList,
        postQuote: API.postQuote,
        postCatalogueList: API.postCatalogueList,
        postDeposit: API.postDeposit,
        desactivateDeposit: API.desactivateDeposit,
        postMargin: API.postMargin,
        postMarginsList: API.postMarginsList,
        postContact: API.postContact,
        postContactsListFilter: API.postContactsListFilter,
        postSettings: API.postSettings,
        settingsInterlocutorPost: API.settingsInterlocutorPost,
        settingsAccountantPost: API.settingsAccountantPost,
        postDepositsList: API.postDepositsList,
        postPersonalCatalog: API.postPersonalCatalog,
        postCatalogsListFilter: API.postCatalogsListFilter,
        postValidPersonalItem: API.postValidPersonalItem,
        postSuppliersList: API.postSuppliersList,
        postOrder: API.postOrder,
        postOrderFilterList: API.postOrderFilterList,
        postCredit: API.postCredit,
        postCreditFilterList: API.postCreditFilterList,
        postDeliveriesFilterList: API.postDeliveriesFilterList,
        postDelivery: API.postDelivery,
        quoteMail: API.quoteMail,
      }}
    >
      {props.children}
    </PostApiContext.Provider>
  );
}
let token = localStorage.getItem("acces_token");
var headers = {
  "Content-Type": "application/json",
  ...(token && { Authorization: "Bearer " + token }),
};
const capboxApi = process.env.REACT_APP_CAPBOXV2_SERVER;

const API = {
  postInvoice: async (invoice, id) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(invoice),
    };

    try {
      const response = await fetch(
        `${capboxApi}c-valid-invoice&id=${id}`,
        header
      );
      return handleApiResponse(response);
    } catch (error) {
      console.error("Error in postInvoice:", error);
      throw error;
    }
  },
  postInvoicesList: async (searchData) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(searchData),
    };
    console.log("header", header);

    try {
      const response = await fetch(
        `${capboxApi}c-invoices-list-filter`,
        header
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  stateChange: async (data) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${capboxApi}c-state-change`, header);
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },

  login: async (values) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(values),
    };
    try {
      const response = await fetch(`${capboxApi}login`, header);
      return handleApiResponse(response);
    } catch (e) {
      //Page erreur accès serveur
      console.log("catch", e);
      return e;
    }
  },
  postQuotesList: async (
    searchData,
    sort,
    quoteState,
    currentPage,
    perPage
  ) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(searchData),
    };
    try {
      const response = await fetch(
        `${capboxApi}c-quotes-list-filter&sort=${sort}&state=${quoteState}&currentPage=${currentPage}&perPage=${perPage}`,
        header
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  postQuote: async (quote, id) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(quote),
    };
    try {
      const response = await fetch(
        `${capboxApi}c-valid-quote&id=${id}`,
        header
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  postCatalogueList: async (currentPage, perPage, values) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ values }),
    };
    try {
      const response = await fetch(
        `${capboxApi}c-catalogs-list&currentPage=${currentPage}&perPage=${perPage}`,
        header
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  postDeposit: async (deposit, id) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(deposit),
    };
    try {
      const response = await fetch(
        `${capboxApi}c-valid-deposit&id=${id}`,
        header
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  desactivateDeposit: async (id) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ id }),
    };
    try {
      const response = await fetch(
        `${capboxApi}c-deactive-deposit&id=${id}`,
        header
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  postMargin: async (id, values) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(values),
    };

    try {
      const response = await fetch(
        `${capboxApi}c-valid-margin&id=${id}`,
        header
      );
      return handleApiResponse(response);
    } catch (error) {
      console.error("Error in postInvoice:", error);
      throw error;
    }
  },
  postMarginsList: async (values) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(values),
    };
    try {
      const response = await fetch(`${capboxApi}c-margins-filter-list`, header);
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  postContact: async (id, contact) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(contact),
    };
    try {
      const response = await fetch(
        `${capboxApi}c-valid-contact-pop&id=${id}`,
        header
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  postContactsListFilter: async (state, values) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(values),
    };
    try {
      const response = await fetch(
        `${capboxApi}c-contacts-list-pop-filter&state=${state}`,
        header
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  postSettings: async (values, imageFile, pdfFile) => {
    updateHeaders();

    // Créer une instance de FormData
    const formData = new FormData();

    // Ajouter toutes les autres valeurs (les autres champs du formulaire) à FormData
    for (const key in values) {
      formData.append(key, values[key]);
    }

    // Ajouter l'image à FormData
    if (imageFile) {
      formData.append("imageFile", imageFile);
    }

    // Ajouter le PDF à FormData, si nécessaire
    if (pdfFile) {
      formData.append("pdfFile", pdfFile);
    }

    const header = {
      method: "POST",
      headers: headers,
      body: formData,
    };
    try {
      const response = await fetch(`${capboxApi}c-valid-settings`, header);
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  settingsInterlocutorPost: async (id, values) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(values),
    };
    try {
      const response = await fetch(
        `${capboxApi}c-valid-interlocutor&id=${id}`,
        header
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  settingsAccountantPost: async (id, values) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(values),
    };
    try {
      const response = await fetch(
        `${capboxApi}c-valid-accountant&id=${id}`,
        header
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  postDepositsList: async (searchData) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(searchData),
    };
    try {
      const response = await fetch(`${capboxApi}c-deposits-list`, header);
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  postPersonalCatalog: async (values) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(values),
    };
    try {
      const response = await fetch(
        `${capboxApi}c-valid-personal-catalog`,
        header
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  postCatalogsListFilter: async (
    searchData,
    switchedItem,
    currentPage,
    perPage
  ) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(searchData),
    };
    try {
      const response = await fetch(
        `${capboxApi}c-catalogs-list-pop-filter&articleState=${switchedItem}&currentPage=${currentPage}&perPage=${perPage}`,
        header
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  postValidPersonalItem: async (values) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(values),
    };
    try {
      const response = await fetch(`${capboxApi}c-valid-personalItem`, header);
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  postSuppliersList: async (searchData) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(searchData),
    };
    try {
      const response = await fetch(`${capboxApi}c-suppliers-list`, header);
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  postOrder: async (id, order) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(order),
    };
    try {
      const response = await fetch(
        `${capboxApi}c-valid-order-form&id=${id}`,
        header
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  postOrderFilterList: async (values) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(values),
    };
    try {
      const response = await fetch(`${capboxApi}c-order-forms-list`, header);
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  postCredit: async (id, credit) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(credit),
    };
    try {
      const response = await fetch(
        `${capboxApi}c-valid-credit&id=${id}`,
        header
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  postCreditFilterList: async (
    values,
    creditState,
    sort,
    currentPage,
    perPage
  ) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(values),
    };
    try {
      const response = await fetch(
        `${capboxApi}c-credits-list-filter&sort=${sort}&state=${creditState}&currentPage=${currentPage}&perPage=${perPage}`,
        header
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  postDeliveriesFilterList: async (values, currentPage, perPage) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(values),
    };
    try {
      const response = await fetch(
        `${capboxApi}c-deliveries-filter-list&currentPage=${currentPage}&perPage=${perPage}`,
        header
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  postDelivery: async (id, delivery) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(delivery),
    };
    try {
      const response = await fetch(
        `${capboxApi}c-valid-delivery&id=${id}`,
        header
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
  quoteMail: async (values) => {
    updateHeaders();
    const header = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(values),
    };

    try {
      const response = await fetch(`${capboxApi}c-quote-send-email`, header);
      return handleApiResponse(response);
    } catch (e) {
      console.log("catch", e);
      return e;
    }
  },
};

const handleApiResponse = async (response) => {
  try {
    const text = await response.text();
    // console.log("Réponse brute:", text);

    let token, responseData;
    if (text.includes("}{")) {
      // Si la réponse contient plusieurs objets JSON (token & data)
      const [tokenText, responseDataText] = text.split(/(?<=\})(?=\{)/);
      try {
        token = JSON.parse(tokenText);
        responseData = JSON.parse(responseDataText);
      } catch (e) {
        console.error(
          "Erreur lors du parsing JSON:",
          e,
          "-------------------------------------------------------------------------------------------- Reponse brute:",
          text
        );
        return;
      }
      if (token && token.token) {
        localStorage.setItem("acces_token", token.token);
      }
    } else if (text !== "") {
      // Si la réponse n'est pas vide et contient un seul objet JSON (data)
      try {
        responseData = JSON.parse(text);
      } catch (e) {
        console.error(
          "Erreur lors du parsing JSON:",
          e,
          "-------------------------------------------------------------------------------------------- Reponse brute:",
          text
        );
        return;
      }
    }

    return responseData;
  } catch (error) {
    console.error("Erreur lors du traitement de la réponse API:", error);
  }
};

function updateHeaders() {
  token = localStorage.getItem("acces_token");
  headers = {
    ...(token && { Authorization: "Bearer " + token }),
  };
}
