import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import MainButton from "../buttonComponent/MainButton";
import DropDownButton from "../buttonComponent/DropDownButton";
import DeletButton from "../deletButton";
import Calendar from "react-calendar";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { DateFormat } from "../../../utils/dateFormat";
import { useEventScroll } from "../../../hooks/useEventClickListener";
import { AppContext } from "../../../context/appContext";
import retour from "../../../assets/images/icons/Picto/Retour.svg";

export default function EditingMainHeader({
  backRedirect,
  defaultRef,
  dateRef,
  headerTitle,
  headerButtonsList,
  refRef,
  id,
  endPoint,
  destination,
  roleId,
}) {
  const [showCalendar, setShowCalendar] = useState(false);
  const { dropdown, mainButton, otherButtons } = headerButtonsList;

  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const { hasScrolled } = useEventScroll();

  useEffect(() => {
    document.querySelector("body").style.overflow = showCalendar
      ? "hidden"
      : "auto";
  }, [showCalendar]);

  const handleCalendarChange = (e) => {
    dateRef.current = DateFormat(e);
    setShowCalendar(false);
    console.log(dateRef?.current);
  };

  const validPopContent = {
    title: "Suppression",
    desc: `Êtes-vous sûr de vouloir supprimer ${
      headerTitle.toLowerCase() === "devis" ||
      headerTitle.toLowerCase() === "bon"
        ? "ce "
        : headerTitle.toLowerCase() === "acompte" ||
          headerTitle.toLowerCase() === "avoir"
        ? "cet "
        : "cette "
    } ${headerTitle.toLowerCase()} ?`,
  };

  return (
    <>
      {showCalendar && roleId && (
        <>
          <div
            onClick={() => setShowCalendar(false)}
            className="grayBlur"
          ></div>
          <Calendar onChange={handleCalendarChange} />
        </>
      )}
      <header className="toolbar">
        <div className="toolbar__container">
          <div className="toolbar__container_header">
            {backRedirect && (
              <img
                src={retour}
                onClick={() => {
                  localStorage.removeItem("parentPath");
                  navigate(backRedirect);
                  appContext.setShowContactPopup(false);
                }}
                className="toolbar__container_header_backIcon"
                alt={"retour"}
              />
            )}
            <h1 className="toolbar__container_header_title">{headerTitle}</h1>
            <input
              placeholder="Référence"
              type="text"
              name="ref"
              className="mediumFont"
              defaultValue={defaultRef}
              maxLength={15}
              size={16}
              // Size is the width of the input in characters
              ref={refRef}
            />
            <div className="toolbar__container_header_date" id="date">
              {roleId && dateRef && (
                <p
                  className={"toolbar__container_header_date_icon"}
                  onClick={() => setShowCalendar(true)}
                >
                  <FontAwesomeIcon icon={faCalendarDays} />
                </p>
              )}
              {dateRef?.current && (
                <p className={"toolbar__container_header_date_value"}>
                  {dateRef.current.split("-").reverse().join("/")}
                </p>
              )}
            </div>
          </div>
          <aside className="toolbar__container_tools">
            {otherButtons &&
              otherButtons
                .filter(
                  (button) =>
                    button.showByRoleId === false ||
                    (button.showByRoleId === true && button.verifRole !== true)
                )
                .map((button, index) => {
                  return (
                    <MainButton
                      key={index}
                      buttonIcon={button.icon}
                      buttonText={button.title}
                      buttonAction={button.action}
                      className={`mainHeader__headerContainer_buttonContainer_${button.className}`}
                    />
                  );
                })}
            {dropdown && <DropDownButton items={dropdown} />}
            {roleId && (
              <MainButton
                buttonIcon={mainButton.buttonIcon}
                buttonText={mainButton.buttonText}
                buttonAction={mainButton.buttonAction}
              />
            )}
            {id && roleId && endPoint && destination && (
              <DeletButton
                id={id}
                destination={destination}
                url={endPoint}
                validPopContent={validPopContent}
                color={"white"}
              />
            )}
          </aside>
        </div>
        <hr
          style={!hasScrolled ? { boxShadow: "none" } : {}}
          className="toolbar__line"
        />
      </header>
    </>
  );
}
