import { useEffect, useState } from "react";
import Decimal from "decimal.js";

export default function DeliveryLine({
  setRefresh,
  listUnit,
  handleLineChange,
  vatList,
  line,
  handleMoveLine,
  linesLength,
  deleteLineByNotId,
  index,
}) {
  const [lineUpdate, setLineUpdate] = useState({});
  const [unitSelected, setUnitSelected] = useState(0);
  const [deliveredQuantity, setDeliveredQuantity] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    // console.log(line)
    setUnitSelected(line.unitId);
    setLineUpdate(line);
    setDeliveredQuantity(line.deliveredQuantity);
    setUnitPrice(line.unitPrice);
  }, [line]);

  useEffect(() => {
    const quantityDecimal = new Decimal(
      line && line?.invoicedQuantity && line?.invoicedQuantity.toString() !== ""
        ? line?.invoicedQuantity
        : 0
    );
    const unitPriceDecimal = new Decimal(unitPrice ? unitPrice : 0);
    setTotal(quantityDecimal.times(unitPriceDecimal)?.toString());
  }, [deliveredQuantity, line, unitPrice]);

  return (
    <div className="invoiceLine" id={line.numero} style={{ height: "auto" }}>
      <div className="invoiceLine__leftContent">
        <div className="invoiceLine__leftContent_header">
          <div className="invoiceLine__leftContent_header_leftPart">
            <p className="invoiceLine__leftContent_header_leftPart_number">
              {index + 1}
            </p>
          </div>
        </div>
        <textarea
          onBlur={(e) => handleLineChange(e, line.id)}
          defaultValue={lineUpdate?.name}
          className="invoiceLine__leftContent_textarea"
          name="name"
          id="articleDesc"
          cols="30"
          rows="10"
          placeholder="Désignation de l'article"
        ></textarea>
      </div>
      <div className="invoiceLine__rightContent">
        <div className="invoiceLine__rightContent_inputContainer">
          <label
            className="invoiceLine__rightContent_inputContainer_label"
            htmlFor="unit"
          >
            Unité
          </label>
          <select
            value={unitSelected ?? line.unitId ?? 0}
            className="invoiceLine__rightContent_inputContainer_input"
            name="unitId"
            id="unit"
            disabled
          >
            {listUnit &&
              listUnit.length > 0 &&
              listUnit.map((unit, index) => {
                return (
                  <option
                    className="invoiceLine__rightContent_inputContainer_input_option"
                    key={index}
                    value={unit.id}
                  >
                    {unit.unit_name}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="invoiceLine__rightContent_inputContainer">
          <label
            className="invoiceLine__rightContent_inputContainer_label"
            htmlFor="quantity"
          >
            Qte commandée
          </label>
          <input
            defaultValue={lineUpdate?.invoicedQuantity}
            className="invoiceLine__rightContent_inputContainer_input"
            id="quantity"
            name="quantity"
            type="number"
            placeholder="0"
            disabled
          />
        </div>
        <div className="invoiceLine__rightContent_inputContainer input-enabled">
          <label
            className="invoiceLine__rightContent_inputContainer_label"
            htmlFor="deliveredQuantity"
          >
            Qte livrée
          </label>
          <input
            onBlur={(e) => handleLineChange(e, line.id)}
            min="0"
            onChange={(e) => setDeliveredQuantity(e.target.value)}
            onWheel={(e) => e.target.blur()}
            defaultValue={lineUpdate?.deliveredQuantity}
            className="invoiceLine__rightContent_inputContainer_input"
            id="deliveredQuantity"
            name="deliveredQuantity"
            type="number"
            placeholder="0"
          />
        </div>
        <div className="invoiceLine__rightContent_inputContainer">
          <label
            className="invoiceLine__rightContent_inputContainer_label"
            htmlFor="unitPrice"
          >
            Prix HT
          </label>
          <input
            defaultValue={lineUpdate.unitPrice}
            className="invoiceLine__rightContent_inputContainer_input"
            id="unitPrice"
            name="unitPrice"
            type="number"
            placeholder="12,30 €"
            disabled
          />
        </div>
        <div className="invoiceLine__rightContent_inputContainer">
          <label
            className="invoiceLine__rightContent_inputContainer_label"
            htmlFor="vat"
          >
            TVA
          </label>
          <select
            value={lineUpdate?.vatId || 0}
            className="invoiceLine__rightContent_inputContainer_input"
            name="vatId"
            id="vat"
            disabled
          >
            {vatList &&
              vatList.length > 0 &&
              vatList.map((vat, index) => {
                return (
                  <option
                    className="invoiceLine__rightContent_inputContainer_input_option"
                    key={index}
                    value={vat.id}
                  >
                    {vat.rate}%
                  </option>
                );
              })}
          </select>
        </div>
        <div className="invoiceLine__rightContent_totalContainer">
          <p className="invoiceLine__rightContent_totalContainer_total">
            Montant HT : &nbsp;<span>{total} €</span>
          </p>
          <p className="invoiceLine__rightContent_totalContainer_total line-discount-total">
            Montant après remise : &nbsp;<span> {total}&nbsp;€</span>
          </p>
        </div>
      </div>
    </div>
  );
}
