import { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { faFileMedical } from "@fortawesome/free-solid-svg-icons";
import { PostApiContext } from "../../context/apiCalls/postApiCalls";

import Pagination from "../../components/addOns/Pagination";
import MainHeader from "../../components/addOns/headers/MainHeader";
import SearchComponent from "../../components/addOns/filterComponent/searchComponent";
import SortByFilter from "../../components/addOns/filterComponent/SortByFilter";
import Loader from "../../components/addOns/loader";
import Card from "../../components/addOns/card";
import { useAuthState } from "../../context/authContext";
import roleVerify from "../../utils/roleVerify";
import TotalAmonts from "../../components/addOns/TotalAmonts";
function MarginsList() {
  let navigate = useNavigate();
  const postApiContext = useContext(PostApiContext);
  const { roleId } = useAuthState();
  const roleList = ["1", "2", "3", "4", "5", "6"];
  const roleVerif = roleVerify(roleList, roleId);

  const [margins, setMargins] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState();
  const [nbPages, setNbPages] = useState();
  const [sort, setSort] = useState("datedown");
  const [reload, setReload] = useState();
  const [loading, setLoading] = useState(true);
  const [totalHT, setTotalHT] = useState(0);

  //const for research component
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const societyRef = useRef();
  const invoiceFilterRef = useRef();
  const marginRef = useRef();
  const [dateRange, setDateRange] = useState();

  /**
   * content for research component
   */
  const contentObject = {
    ContentName: "MARGE",
    Content: [
      { name: "firstName", title: "Prénom", type: "text", ref: firstNameRef },
      { name: "lastName", title: "Nom", type: "text", ref: lastNameRef },
      { name: "society", title: "Société", type: "text", ref: societyRef },
      {
        name: "refFacture",
        title: "Référence facture",
        type: "text",
        ref: invoiceFilterRef,
      },
      {
        name: "refMargin",
        title: "Référence marge",
        type: "text",
        ref: marginRef,
      },
      {
        name: "dateRange",
        title: "Date de création",
        type: "dateRangePicker",
        ref: dateRange,
      },
    ],
  };

  /**
   * options for SortByFilter
   */
  const sortOptions = {
    options: [
      { value: "datedown", content: "Date décroissante" },
      { value: "dateup", content: "Date croissante" },
      { value: "refup", content: "Références croissantes" },
      { value: "refdown", content: "Références décroissantes" },
    ],
  };
  useEffect(() => {
    setMargins(null);
    setLoading(true);

    const values = {
      firstName: firstNameRef.current?.value,
      lastName: lastNameRef.current?.value,
      society: societyRef.current?.value,
      refInvoice: invoiceFilterRef.current?.value,
      refMargin: marginRef.current?.value,
      sort: sort,
      startDate:
        dateRange && dateRange[0]
          ? dateRange[0].toLocaleDateString().split("T")[0]
          : null,
      endDate:
        dateRange && dateRange[1]
          ? dateRange[1].toLocaleDateString().split("T")[0]
          : null,
      perPage: perPage,
      currentPage: currentPage,
    };

    // console.log("values :", values);

    postApiContext
      .postMarginsList(values)
      .then((response) => {
        console.log("response :", response);
        setMargins(response.marginsList);
        setNbPages(response.nbPages);
        setTotalHT(response.totalAmount.total_ht);
        setLoading(false);
      })
      .catch((error) => {
        console.log("erreur :", error);
      });

    setReload(false);
  }, [postApiContext, sort, reload, dateRange, perPage, currentPage]);

  const openMargin = (marginId) => {
    localStorage.removeItem("parentPath");
    navigate("/marginsList/margin/" + marginId);
  };

  const openEmptyMargin = () => {
    localStorage.removeItem("parentPath");
    navigate("/marginsList/margin/");
  };

  //Reset research component
  const refresh = () => {
    const formulaire = document.getElementById("searchFilterForm");
    formulaire.reset();
    setSort("refdown");
    setDateRange([]);
    setReload(true);
  };

  const onSearchSelectChange = (e) => {
    console.log(e.target.value);
    //setInterlocutorSearch(e.target.value);
  };

  const onValidFilter = async (e) => {
    e.preventDefault();
    setReload(true);
  };

  const objectButtons = [
    {
      title: "Nouvelle marge",
      className: "",
      icon: faFileMedical,
      action: openEmptyMargin,
      showByRoleId: true,
    },
  ];

  return (
    <>
      {loading && <Loader />}

      <MainHeader headerTitle={"MARGES"} objectButtons={objectButtons} />

      <section className="listPage">
        <div className="listPage__filterContainer">
          <SearchComponent
            refresh={refresh}
            onSearchSelectChange={onSearchSelectChange}
            onSearchSubmit={onValidFilter}
            contentObject={contentObject}
            interlocutor={/*interlocutors*/ ""}
            dateRange={dateRange}
            setDateRange={setDateRange}
            className={"without-stateFilter"}
          />

          <SortByFilter
            sortOptions={sortOptions}
            setSort={setSort}
            onFilterSubmit={"onValidFilter"}
          />
        </div>
        {margins?.map((margin, k) => {
          // console.log("margin :", margin);
          const element = {
            id: margin.id,
            valid: 0,
            state: "",
            date: margin.dateInvoice,
            ref: margin.ref,
            subject: "",
            lastName: margin.contact.lastName,
            firstName: margin.contact.firstName,
            society: margin.contact.society,
            totalHt: margin.totalHT ?? 0,
            totalTtc: margin.invoice.totalTtc,
            directionPath: "/marginsList/margin/",
            initials: margin.user.initials,
            origin: margin.invoice?.ref
              ? "Facture n°" + margin.invoice.ref
              : margin.quote?.ref
              ? "Devis n°" + margin.quote.ref
              : "",
          };
          return (
            <Card
              roleVerif={roleVerif}
              key={k}
              element={element}
              tableName={"margin"}
              handleOpenValidItem={openMargin}
              stateButtonsList={[]}
              setListRefresh={() => setReload(true)}
              deleteEndPoint={"c-deactive-margin"}
              otherButtonList={{}}
              elementTitle={"MARGE N°"}
            />
          );
        })}
        <TotalAmonts totalHT={totalHT} />
        <Pagination
          perPage={perPage}
          setPerPage={setPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          nbPages={nbPages}
        />
      </section>
    </>
  );
}

export default MarginsList;
