import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import EditingMainHeader from "../../../components/addOns/headers/EditingMainHeader";
import DeliveryInfos from "../../../components/customer/invoices/delivery/DeliveryInfos";
import DeliveryGroup from "../../../components/customer/invoices/delivery/DeliveryGroup";
import DeliveryPrice from "../../../components/customer/invoices/delivery/DeliveryPrice";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { GetApiContext } from "../../../context/apiCalls/getApiCalls";
import { PostApiContext } from "../../../context/apiCalls/postApiCalls";
import { ContactPopContext } from "../../../context/ContactPopContext";
import { useAuthState } from "../../../context/authContext";

function Delivery() {
  let id = useParams();
  let navigate = useNavigate();
  const getApiContext = useContext(GetApiContext);
  const postApiContext = useContext(PostApiContext);
  const contactPopContext = useContext(ContactPopContext);
  const { roleId } = useAuthState();

  const [delivery, setDelivery] = useState();
  const [deliveryGroups, setDeliveryGroups] = useState();
  const refRef = useRef();
  const [ref, setRef] = useState();
  const [interlocutors, setInterlocutors] = useState({});
  const [subject, setSubject] = useState();
  const [date, setDate] = useState();
  const [societyContact, setSocietyContact] = useState();
  const [listUnit, setListUnit] = useState([]);
  const [listTva, setListTva] = useState();
  const [invoice, setInvoice] = useState();

  useEffect(() => {
    getApiContext
      .getDelivery(id.id)
      .then((response) => {
        // console.log("delivery get response :", response);
        setDelivery(response.delivery);
        setRef(response.delivery.ref);
        setDeliveryGroups(response.deliveryGroups);
        setInterlocutors(response.listInterlocutors);
        setSubject(response.delivery.subject);
        setSocietyContact(response.societyContact);
        setListUnit(response.listUnit);
        setListTva(response.listTva);
        setInvoice(response.delivery.invoice);
        setDate(new Date(response.delivery.date));
      })
      .catch((error) => {
        console.log("delivery get error :", error);
      });
  }, [getApiContext, id.id]);

  const setGroupLines = (updatedLines, groupIndex) => {
    const updatedGroups = deliveryGroups.map((group, index) =>
      index === groupIndex ? { ...group, lines: updatedLines } : group
    );
    setDeliveryGroups(updatedGroups);
  };

  const buttonAction = async (e) => {
    //e.preventDefault();

    const formattedDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const newDate = `${year}-${month}-${day}`;
      return newDate;
    };

    const values = {
      deliveryRef: refRef?.current.value,
      invoiceId: delivery.invoice.id,
      commentaire: delivery.comment,
      date_emission: formattedDate(date),
      titre: subject,
      groups: deliveryGroups,
    };

    //Forcer d'avoir un invoiceId
    console.log("post values :", values);
    postApiContext
      .postDelivery(delivery.id, values)
      .then((response) => {
        console.log("post delivery response :", response);
        navigate("/invoicesList/deliveriesList/");
      })
      .catch((error) => {
        console.log("erreur post delivery:", error);
      });
  };

  const deleteAction = (e) => {
    axios
      .get(`${process.env.REACT_APP_DELIVERY_DELETE}${id.id}`)
      .then((response) => {
        console.log(response);
        navigate("/invoicesList/deliveriesList/");
      })
      .catch((error) => {
        console.log("erreur delete : ", error);
      });
  };

  const headerButtonsList = {
    // dropdown: {
    //   imprimer: {
    //     content: "Imprimer",
    //   },
    //   envoyer: {
    //     content: "Envoyer",
    //   },
    // },
    mainButton: {
      buttonIcon: faArrowLeft,
      buttonText: "Sauvegarder",
      buttonAction: (e) => buttonAction(e),
    },
    garbageButton: {
      garbageRedirect: "",
      deleteAction: { deleteAction },
    },
  };

  const handleClickModal = () => {
    contactPopContext.setShowContactPopup(true);
  };

  return (
    <>
      <EditingMainHeader
        backRedirect={"/invoicesList/deliveriesList"}
        defaultRef={ref}
        refRef={refRef}
        date={date || new Date()}
        headerTitle={"Bon"}
        setDate={setDate}
        headerButtonsList={headerButtonsList}
        roleId={roleId}
        endPoint={"c-deactive-delivery"}
        destination={"/invoicesList/deliveriesList"}
        id={id.id}
      />
      <div className="listPage">
        <div className="invoiceContent">
          <div className="invoiceFormContainer">
            <DeliveryInfos
              invoiceRef={delivery?.invoice?.ref}
              interlocutor={delivery?.invoice?.user}
              interlocutors={interlocutors}
              subject={subject}
              setSubject={setSubject}
              societyContact={societyContact}
              handleClickModal={handleClickModal}
              setSocietyContact={setSocietyContact}
            />
            <div className="invoiceFormContainer__groupsContainer">
              <div className="invoiceGroup__titleContainer">
                <h2 className="invoiceGroup__titleContainer_title">
                  Description du produit
                </h2>
                <hr className="invoiceGroup__titleContainer_line" />
              </div>
              {deliveryGroups?.map((group, i) => {
                return (
                  <div key={i}>
                    <DeliveryGroup
                      key={i}
                      index={i}
                      group={group}
                      id={group.id}
                      numero={i + 1}
                      designation={group.title === undefined ? "" : group.title}
                      listUnit={listUnit}
                      vatList={listTva}
                      delivery={delivery}
                      setGroupLines={setGroupLines}
                    />
                    <hr className="invoiceFormContainer__groupsContainer_line" />
                  </div>
                );
              })}
            </div>
          </div>
          <DeliveryPrice
            delivery={delivery}
            invoice={invoice}
            listTva={listTva || {}}
          />
        </div>
      </div>
    </>
  );
}

export default Delivery;
