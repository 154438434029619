import { useEffect, useRef, useState, useCallback, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import {
  faFloppyDisk,
  faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";
import EditingMainHeader from "../../components/addOns/headers/EditingMainHeader";
import { GetApiContext } from "../../context/apiCalls/getApiCalls";
import { useAuthState } from "../../context/authContext";
import { PostApiContext } from "../../context/apiCalls/postApiCalls";
import Loader from "../../components/addOns/loader";
import { getCurrentDate } from "../../utils/utils";
import ContactInfosPanel from "../../components/customer/editingPages/informations/ContactInfosPanel";
import { PopupContext } from "../../context/PopupContext";
import DepositInputs from "../../components/customer/deposit/DepositInputs";
import Total from "../../components/customer/invoices/invoice/recap/total";
import { AppContext } from "../../context/appContext";
import MailPop from "../../components/addOns/MailPop";

export default function Deposit({ depositId, setRefresh }) {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const idPage = useLocation().pathname.split("/")[3].split("-")[1];
  const locationId = useLocation().pathname.split("/")[3].split("-")[0];
  let id = depositId || locationId;
  const getApiContext = useContext(GetApiContext);
  let postApiContext = useContext(PostApiContext);
  const { roleId } = useAuthState();
  const { popups, setPopupVisibility } = useContext(PopupContext);

  // Ref
  const [ref, setRef] = useState("");
  const refRef = useRef("");
  const rateRef = useRef(null);
  const totalTTCRef = useRef(null);
  const titleRef = useRef(null);
  const descRef = useRef(null);
  const dateRef = useRef(null);
  let oldId = useRef(null);

  // State
  const [depositDate, setDepositDate] = useState(null);
  const [payementDate, setPayementDate] = useState(null);
  const [calendarPop, setCalendarPop] = useState(false);
  const [buttonId, setbuttonId] = useState(null);
  const [data, setData] = useState(undefined);
  const [dataContact, setDataContact] = useState(null);
  const [dataQuoteInvoice, setDataQuoteInvoice] = useState(null);
  const [quote, setQuote] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [totalTtc, setTotalTtc] = useState(0);
  const [showCalendar, setShowCalendar] = useState(false);
  const [loading, setLoading] = useState(true);

  const [rate, setRate] = useState(0);
  const [linkDeposit, setLinkDeposit] = useState([]);
  const [totalLinkDeposit, setTotalLinkDeposit] = useState(0);
  const [leftToPay, setLeftToPay] = useState(null);
  const [depositTotalTtc, setDepositTotalTtc] = useState(0);
  const [calc, setCalc] = useState(null);

  const fetchDepositData = useCallback(async () => {
    try {
      if (!(id === oldId.current)) {
        oldId.current = id;
        setLoading(true);
        let idQuote = null;
        let idInvoice = null;
        let idDeposit = null;
        if (idPage === "quote") {
          idQuote = id;
        } else if (idPage === "invoice") {
          idInvoice = id;
        } else {
          idDeposit = id;
        }
        getApiContext
          .getDeposit(idDeposit, idQuote, idInvoice)
          .then((response) => {
            console.log("id", id, "idpage", idPage);
            const depositData = response?.deposit?.deposit || null;
            setData(depositData);
            setRef(depositData?.ref || "");
            setInvoice(depositData?.invoice || null);
            setQuote(depositData?.quote || null);
            setDataQuoteInvoice(
              depositData?.invoice || depositData?.quote || null
            );
            setLinkDeposit(response?.linkDeposit);
            setLoading(false);
            dateRef.current = depositData?.depositDate || getCurrentDate();
          });
      }
    } catch (error) {
      console.error(error);
    }
  }, [getApiContext, id, idPage]);

  useEffect(() => {
    fetchDepositData();
  }, [fetchDepositData]);

  useEffect(() => {
    setTotalTtc(
      invoice?.totalTtc ??
        quote?.totalTtc ??
        data?.quote?.totalTtc ??
        data?.totalTtc ??
        0
    );
    const totalLinkDepositMap =
      linkDeposit?.map((element) => element.totalTtc) ?? [];
    const sommeTotalTtc = totalLinkDepositMap
      .map(Number)
      .reduce((a, b) => a + b, 0);
    setTotalLinkDeposit(sommeTotalTtc);

    setCalc(totalTtc - sommeTotalTtc);
    setLeftToPay(totalTtc - sommeTotalTtc);

    setRate(data?.rate ?? 0);
    setDepositTotalTtc(data?.totalTtc ?? 0);

    if (totalTtc && totalTtc.toString() === "0") {
      const rateData = data?.deposit?.rate;
      const calcTtc = (rateData * calc) / 100;
      setDepositTotalTtc(calcTtc?.toFixed(2));
    }

    if (!dataContact) {
      setDataContact(data?.quote?.contact ?? data?.invoice?.contact ?? null);
    }
  }, [data, quote, invoice, linkDeposit, id, totalTtc, dataContact, calc]);

  const handleCalc = (e) => {
    e.preventDefault();
    if (e.target.id === "totalDepositInput") {
      const totalCalc = totalTtc - totalLinkDeposit - e.target.value;
      const rateCalc = (e.target.value / totalTtc) * 100;
      setRate(rateCalc.toFixed(2) || 0);
      setLeftToPay(totalCalc);
      setDepositTotalTtc(e.target.value);
    } else if (e.target.id === "rateInput") {
      const rateCalc = (totalTtc * e.target.value) / 100;
      const rate = totalTtc - totalLinkDeposit - rateCalc;
      setRate(e.target.value);
      setDepositTotalTtc(rateCalc?.toFixed(2));
      setLeftToPay(rate);
    }
  };

  const onSubmit = (e) => {
    //console.log("deposit submit")
    let path =
      idPage === "quote"
        ? `/quotesList/quote/${quote?.id}`
        : idPage === "invoice"
        ? `/invoicesList/invoice/${invoice?.id}`
        : "/depositsList/";
    onValidDeposit(e, path);
    fetchDepositData();
  };

  const onValidDeposit = (
    e,
    path = `/depositsList/deposit/${
      localStorage.getItem("parentPath").includes("invoice")
        ? invoice?.id
        : quote?.id
    }-${
      localStorage.getItem("parentPath").includes("invoice")
        ? "invoice"
        : "quote"
    }`
  ) => {
    e.preventDefault();
    console.log(path);
    const values = {
      idDeposit: data?.id ?? null,
      idQuote: data?.quote?.id || (idPage === "quote" ? id : null),
      idInvoice: data?.invoice?.id || (idPage === "invoice" ? id : null),
      ref: refRef.current.value,
      depositDate: dateRef.current ?? depositDate,
      paymentDate: payementDate ? payementDate : data?.paymentDate,
      rate: rateRef.current.value,
      totalTTC: totalTTCRef.current.value,
      subject: titleRef?.current?.value,
      comment: descRef?.current?.value,
    };

    postApiContext
      .postDeposit(values)
      //axios.post(`${process.env.REACT_APP_DEPOSIT_QUOTE_POST}`, values, customHeaders,)
      .then((response) => {
        // document.querySelector("form").reset();
        fetchDepositData();
        if (!depositId) {
          handleNavigate(path);
        }
        if (setRefresh) {
          setRefresh(true);
        }
        setPopupVisibility("showDepositPopup", false);
        // window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNavigate = (path = "") => {
    let newPath = localStorage.getItem("parentPath");
    navigate(path !== "" ? path : newPath ?? "/depositsList");
  };

  function handleNavigateFunc(path = "") {
    let newPath = localStorage.getItem("parentPath");
    return newPath ? newPath : path ? path : "/depositsList";
  }

  // const handleDelete = (depositId) => {
  //     //e.preventDefault()
  //     console.log("delete", depositId)
  //     postApiContext.desactivateDeposit(depositId)
  //         .then(response => {
  //             fetchDepositData();
  //             window.location.reload();
  //         })
  //     if (depositId === id) {
  //         navigate(handleNavigateFunc())
  //     }
  // }

  const filterDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month}-${day}`;
  };

  const handleClick = (e) => {
    const id = e.target.id;
    if (id === "depositDate") {
      setCalendarPop(true);
      setbuttonId("1");
    } else if (id === "payementDate") {
      setCalendarPop(true);
      setbuttonId("2");
    }
  };

  const handleChange = (e) => {
    if (buttonId === "1") {
      setDepositDate(filterDate(e));
    } else {
      setPayementDate(filterDate(e));
    }
  };

  const headerButtonsList = {
    dropdown: {
      telecharger: {
        content: "Télécharger",
        handleClick: () => handlePDF(id, false),
        redir: false,
      },
      imprimer: {
        content: "Imprimer",
        handleClick: () => handlePDF(id, true),
        redir: false,
      },
      envoyer: {
        content: "Envoyer",
        handleClick: () => {
          appContext.setShowMailPop(true);
        },
        redir: false,
      },
    },
    mainButton: {
      buttonIcon: faFloppyDisk,
      buttonText: id ? "Sauvegarder" : "Créer le devis",
      buttonAction: (e) => onValidDeposit(e, handleNavigateFunc()),
    },
  };

  const handlePDF = async (id, isPrinting) => {
    try {
      let fileURL = await getPDF(id); // Attendez l'URL générée
      console.log("fileURL", fileURL);

      if (!fileURL) {
        console.error("Impossible de récupérer l'URL du PDF.");
        setLoading(false);
        return;
      }

      const newTab = window.open(fileURL);

      if (newTab) {
        // Attendre que le fichier soit chargé, puis imprimer
        if (isPrinting) {
          newTab.onload = () => {
            newTab.print();
          };
        }
      } else {
        setLoading(false);
        console.error(
          "Impossible d'ouvrir un nouvel onglet. Vérifiez les bloqueurs de pop-up."
        );
      }
    } catch (error) {
      console.error("Erreur lors de l'impression du PDF :", error);
      setLoading(false);
    }
  };

  const getPDF = async (id) => {
    setLoading(true);
    try {
      const response = await getApiContext.getDepositPDF(id); // Attendez la réponse de l'API
      console.log("response", response);

      if (!response || !response.data) {
        console.error("Aucune donnée PDF reçue.");
        setLoading(false);
        return null;
      }

      // Convertir la réponse en Blob
      const file = new Blob([response.data], { type: "application/pdf" });
      // Créer une URL temporaire pour le Blob
      const fileURL = URL.createObjectURL(file);
      setLoading(false);
      return fileURL;
    } catch (error) {
      console.error("Erreur lors de la récupération du PDF :", error);
      setLoading(false);
      return null;
    }
  };

  return (
    <>
      <MailPop endpoint={"c-deposit-send-email"} id={id} />
      {loading && <Loader />}
      {calendarPop && (
        <div onClick={() => setCalendarPop(false)} className="grayBlur"></div>
      )}
      {calendarPop && (
        <>
          <Calendar onChange={handleChange} />
        </>
      )}
      <EditingMainHeader
        headerTitle={"Acompte"}
        dateRef={dateRef}
        setShowCalendar={setShowCalendar}
        showCalendar={showCalendar}
        destination={handleNavigateFunc()}
        endPoint={"c-deactive-deposit"}
        id={id}
        refRef={refRef}
        defaultRef={ref}
        roleId={roleId}
        headerButtonsList={headerButtonsList}
        backRedirect={popups.showDepositPopup ? null : "/depositsList"}
      />

      <form onSubmit={onSubmit} className="listPage">
        <div className="block">
          <div className="editingInfos">
            <div className="editingInfos__titleContainer">
              <h2 className="editingInfos__titleContainer_title">
                informations
              </h2>
              <hr className="editingInfos__titleContainer_line" />
            </div>
            <div className="editingInfos__firstSection">
              <div className="editingInfos__firstSection_nameContainer">
                <h3 className="editingInfos__firstSection_nameContainer_title">
                  Intitulé de l'acompte
                </h3>
                <input
                  className={"editingInfos__firstSection_nameContainer_input"}
                  defaultValue={data?.subject}
                  ref={titleRef}
                  placeholder="Intitulé"
                />
              </div>
              <div className="editingInfos__firstSection_interContainer">
                <h3 className="editingInfos__firstSection_interContainer_title">
                  {data?.quote
                    ? "Référence du devis"
                    : "Référence de la facture"}
                </h3>
                <div className="editingInfos__firstSection_interContainer_content">
                  <input
                    className={"editingInfos__firstSection_nameContainer_input"}
                    value={
                      (data?.quote ? "Devis" : "Facture") +
                      "N°" +
                      dataQuoteInvoice?.ref
                    }
                    readOnly
                  />
                </div>
              </div>
            </div>
            <ContactInfosPanel
              contact={dataContact}
              customClass={"contactInfoPanelw-0-5"}
            />
          </div>
          <div className="contract-body">
            <div className="container">
              <DepositInputs
                rateRef={rateRef}
                totalTTCRef={totalTTCRef}
                rate={rate}
                depositTotalTtc={depositTotalTtc}
                handleCalc={handleCalc}
              />
              <div className="comment">
                <h3>COMMENTAIRE AJOUTE A L'ACOMPTE</h3>
                <hr className="comment line" />
                <textarea
                  ref={descRef}
                  defaultValue={data?.comment}
                  placeholder="Description"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="aside">
            <div className="priceRecap">
              <Total
                title="Total TTC du devis"
                price={`${parseFloat(totalTtc).toFixed(2)}`}
              />
              <Total
                title="Total de l'acompte"
                price={`${parseFloat(depositTotalTtc || "0").toFixed(2)}`}
              />
              <div className="marginPrice__tva">
                <div>
                  <p>Total des acomptes</p>
                  <p className="depositTvaAmount">
                    {totalLinkDeposit.toFixed(2) || 0} €
                  </p>
                </div>
              </div>
              <Total
                title="Reste de la facture à payer"
                price={`${parseFloat(leftToPay || "0").toFixed(2)}`}
              />
              <div className="payementDate">
                <p>
                  <FontAwesomeIcon icon={faMoneyCheckDollar} />
                  Suivi de règlement :
                </p>
                <div>
                  Acompte payé le
                  <p className="calendarLogoBg " onClick={handleClick}>
                    <img
                      className="calendarLogo"
                      id="payementDate"
                      src="/calendar-days-solid.svg"
                      alt=""
                    />
                  </p>
                  <span>
                    &thinsp;&thinsp;&thinsp;
                    {payementDate ? payementDate : data?.paymentDate}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
